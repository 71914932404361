import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PagesTitle from '../../widgets/pages_title/pages_title'
import './project_screen.css'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AddProject, changeGridView, deleteProjectByID, editProjectByID, selectProject, setProjectData } from '../../redux/project/projects'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AppBarWidget from '../../widgets/app_bar/app_bar';
import { useState } from 'react';
import LoginInputWidget from '../../widgets/input/login_input'
import { useEffect } from 'react';
import { GetProjectData, createProjectToDB, changeProjectNameAPI, deleteProjectAPI } from '../../api/project';
import ResponseHttp from '../../widgets/response_http/response_http';
import { requestState } from '../../global/http_enum';
import { cookiesGlobal } from '../../App';
import { showAlert } from '../../functions/show_alert';
import { HideProgressAlert, showProgressAlert } from '../../functions/show_progress_page';
import { hundleRequestFailure } from '../../functions/hundle_request_failure';
import GridProject from './grid-view/grid_project';
import ListProject from './list-view/list_project';
export default function ProjectScreen() {
  const projectState = useSelector(state => state.projectSlice);
  const dispatch = useDispatch();
  const [showCreateProject, SetShowCreateProject] = useState(false);
  const [editProject, SetEditProject] = useState(-1);
  const [projectNewName, SetProjectNewName] = useState("");
  const [projectName, SetProjectName] = useState('');
  const hundleCloseAlert = () => {
    SetProjectName('');
    SetShowCreateProject(false);
  }
  const hundleCloseEditAlert = () => {
    SetProjectNewName('');
    SetEditProject(-1);
  }
  useEffect(() => {
    if (projectState.requestState !== requestState.haveData) {
      const token = cookiesGlobal.get('auth');
      GetProjectData(token).then((e) => {
        dispatch(setProjectData(e));
      })
    }
  }, [],)
  const createProjectFunction = async () => {
    if (projectName === '') {
      showAlert(false, 'Enter Project Name');
      return;
    }
    showProgressAlert();
    const response = await createProjectToDB(projectName);
    HideProgressAlert();
    if (response.statusCode === 200) {

      dispatch(AddProject(response.response));
      hundleCloseAlert();
    } else {
      hundleRequestFailure(response);
    }
  }

  const renameProject = async () => {
    showProgressAlert();
    const response = await changeProjectNameAPI(editProject, projectNewName);
    HideProgressAlert();
    if (response.statusCode < 300) {
      dispatch(editProjectByID({ id: editProject, projectName: projectNewName }));
      hundleCloseEditAlert();
    } else {
      hundleRequestFailure(response);
    }
  }
  return (
    <>
      <AppBarWidget />
      <div id="project-screen-top">
        <PagesTitle title={'Projects'} />
        <div id="project-buttons">
          <div onClick={() => SetShowCreateProject(true)} className="create-project">
            <AddCircleIcon className='icon' />
            Create project
            <ArrowForwardIosIcon className='icon-arrow' />
          </div>
          <div className="grid-view-holder">
            Grid View
            <input onChange={() => dispatch(changeGridView())} defaultChecked={projectState.gridview} value={projectState.gridview} className='grid-view' type="checkbox" />
          </div>
        </div>
      </div>
      <div id='project-screen'>
        <ProjectViewWidget SetEditProject={SetEditProject} SetShowCreateProject={SetShowCreateProject} gridView={projectState.gridview} httpResponse={projectState.requestState} selectedProject={projectState.projectSelected} projects={projectState.project} />
      </div>
      {
        showCreateProject ? <div onClick={(e) => { if (e.target.id === 'show-create-project') { hundleCloseAlert() } }} id="show-create-project">
          <div className="content">
            <h1>Enter The Project Name</h1>
            <LoginInputWidget height={'50px'} maxLength={50} placeholder={'Project Name'} setValue={SetProjectName} type={'text'} value={projectName} />
            <div className="buttons">
              <div onClick={() => hundleCloseAlert()} className="button red">Cancel</div>
              <div onClick={() => createProjectFunction()} className="button">Create</div>
            </div>
          </div>
        </div> : <></>
      }
      {
        editProject !== -1 ? <div onClick={(e) => { if (e.target.id === 'show-create-project') { hundleCloseEditAlert() } }} id="show-create-project">
          <div className="content">
            <h1>Enter The New Project Name</h1>
            <LoginInputWidget height={'50px'} maxLength={50} placeholder={'New Project Name'} setValue={SetProjectNewName} type={'text'} value={projectNewName} />
            <div className="buttons">
              <div onClick={() => hundleCloseEditAlert()} className="button red">Cancel</div>
              <div onClick={() => renameProject()} className="button">Rename</div>
            </div>
          </div>
        </div> : <></>
      }
    </>

  )
}
const ProjectViewWidget = ({ httpResponse, selectedProject, gridView, projects, SetShowCreateProject, SetEditProject }) => {
  const dispatch = useDispatch();
  const hundleSelectProject = (project) => {
    dispatch(selectProject(project));
  }
  const [picked, SetPicked] = useState(-1);
  const deleteProject = async (id) => {
    showProgressAlert();
    const response = await deleteProjectAPI(id);
    HideProgressAlert();
    if (response.statusCode < 300) {
      dispatch(deleteProjectByID(id));
    } else {
      hundleRequestFailure(response);
    }
  }
  if (requestState.haveData === httpResponse) {
    var Selected = selectedProject === null ? '' : selectedProject.id;
    if (gridView) {
      return <div className={`main-projects ${gridView ? 'main-projects-grid ' : 'main-projects-list'}`}>
        {projects.map((e, index) => <GridProject deleteProject={SetPicked} SetEditProject={SetEditProject} Selected={Selected} key={index} projectData={e} hundleSelectProject={hundleSelectProject} />)}
        <div onClick={() => SetShowCreateProject(true)} className="create-probject-grid-view">
          Create Project
        </div>
        {picked === -1 ? <></> : <div onClick={() => SetPicked(-1)} id="delete-item">
          <div className="alert-mesg">
            <h1>Are you sure You want to delete This Project ?</h1>
            <div className="buttons">
              <div onClick={() => SetPicked(-1)} className="bt close">Close</div>
              <div onClick={() => deleteProject(picked)} className="bt done">Confirm</div>
            </div>
          </div>
        </div>}
      </div>
    } else {
      return <div className={`main-projects ${gridView ? 'main-projects-grid ' : 'main-projects-list'}`}>
        {projects.map((e, index) => <ListProject deleteProject={SetPicked} SetEditProject={SetEditProject} Selected={Selected} key={index} projectData={e} hundleSelectProject={hundleSelectProject} />)}
        {picked === -1 ? <></> : <div onClick={() => SetPicked(-1)} id="delete-item">
          <div className="alert-mesg">
            <h1>Are you sure You want to delete This Project ?</h1>
            <div className="buttons">
              <div onClick={() => SetPicked(-1)} className="bt close">Close</div>
              <div onClick={() => deleteProject(picked)} className="bt done">Confirm</div>
            </div>
          </div>
        </div>}
      </div>
    }
  } else {
    return <ResponseHttp response={httpResponse} />
  }
}



