import React, { useState } from 'react'
import FileReaderWidget from '../../../widgets/file_reader/file_reader';
import PagesTitle from '../../../widgets/pages_title/pages_title';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import './event_view.css';
import { HideProgressAlert, showProgressAlert } from '../../../functions/show_progress_page';
import { hundleRequestFailure } from '../../../functions/hundle_request_failure';
import { changeProjectDomainAPI } from '../../../api/project';
import { useDispatch } from 'react-redux';
import { editProjectDomainByID } from '../../../redux/project/projects';
export default function EventView({ event, id }) {
    const [showChangeType, SetShowChangeType] = useState(false);
    const dispatch = useDispatch();
    const changeDomain = async (domain) => {
        showProgressAlert();
        const response = await changeProjectDomainAPI(event.id, domain);
        HideProgressAlert();
        if (response.statusCode < 300) {
            dispatch(editProjectDomainByID({ id, newDomain: domain }));
            SetShowChangeType(false);
        } else {
            hundleRequestFailure(response);
        }
    }
    return (
        <>
            <div id='event-view'>
                <div className="domain-holder">
                    <PagesTitle title={'Event'} />
                    <div onClick={() => SetShowChangeType(true)} className="domain-button">
                        Switch Domain
                        <ImportExportIcon className='icon' />
                    </div>
                </div>
                <div className="box">
                    <h1 className='type'><span>{event.domain} </span> Domain</h1>
                    <div className='divider'></div>
                    <div className="content">
                        {event.file ? <div className="file"><FileReaderWidget file={event.file} type={event.type} /> </div> : <></>}
                        <div className="infromations">
                            <h4 className='information'><span>Event Name : </span> {event.name}</h4>
                            <h4 className='information'><span>Event Location :</span> {event.location}</h4>
                            <h4 className='information'><span>Negative Function Executed :</span> {event.negative_function_exluded}</h4>
                            <h4 className='information'><span>Problem Identified from Event : </span>{event.problem_identifyed_from_event}</h4>
                            <div className='information descriptions'>
                                <span style={{ marginBottom: '10px' }}>Event Description :</span>
                                <p dangerouslySetInnerHTML={{ __html: event.desciption }} className='description-text'></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showChangeType ? <div onClick={() => SetShowChangeType(false)} id='show-event-type'>
                <div className="alert-holder">
                    <div onClick={() => changeDomain('Theory')} className="button">Theory Domain</div>
                    <div onClick={() => changeDomain('Communication')} className="button">Communication Domain</div>
                </div>
            </div> : <></>}
        </>

    )
}
