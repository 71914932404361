const OrderOfTheProblemTheory = [
    'Disregarding Application of Theory',
    'Misinterpretation of Theory',
    'Disregard Importance of Theory',
    'Disregard Relationship of Theory and Theory of Communication',
    'Disregard Portability of Theory',
    'Disregard Theory and System Relationship',
    'Disregard Independency of Theory',
    'Expandability of Philosophy',
    'Misapplication of Instruments',
    'Misuse of Good Instruments',
    'Disregard Instrument and System Relationship',
    'Disregard System and System Relationship',
    'Disregard Function and System Relationship',
    'Misapplication of Method',
    'Application of Bad Methods',
    'Disregard Method and System Relationship',
]

const OrderOfTheProblemComunication = [
    'Perform inappropriate actions',
    'Communicate about performing inappropriate actions',
    'Misuse of instructions',
    'Communicate about misusing of instructions',
    'Use inappropriate instructions',
    'Communicate about using inappropriate instructions',
    'Disregard instructions',
    'Communicate about disregarding instructions',
    'Misuse of objects',
    'Communicate about misuse of objects',
    'Use inappropriate objects',
    'Communicate about using inappropriate objects',
    'Misinformation',
    'Communicate about misinformation',
    'Misinterpretation',
    'Communicate about Misinterpretation',
    'Follow others',
    'Communicate about following others',
    'Miscommunication',
    'Communicate about miscommunication',
    'Exercise of force',
    'Communicate about exercising of force',
    'Disregard principles',
    'Communicate about disregarding principles',
    'Use of bad words',
    'Use of bad expressions',
    'Use of non-portable words',
    'Use of non-portable expressions',
    'Use of words with multiple meanings1',
    'Use of words with multiple meanings2',
    'Word matching error to form expression',
    'Word does not match in sentence',
    'Words do not identify actual entity',
    'Provide misinformation',
]


const SortProblemList = (selectedItems, domain) => {
    if (domain === 'Theory') {
        const sortedSelections = selectedItems.sort((a, b) => {
            return OrderOfTheProblemTheory.indexOf(a) - OrderOfTheProblemTheory.indexOf(b);
        });
        return sortedSelections;
    } else {
        const sortedSelections = selectedItems.sort((a, b) => {
            return OrderOfTheProblemComunication.indexOf(a) - OrderOfTheProblemComunication.indexOf(b);
        });
        return sortedSelections;
    }

}
export {
    SortProblemList
}