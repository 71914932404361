import React, { useState } from 'react'
import './login_input.css';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
export default function LoginInputWidget({ width, height, widget, placeholder, type, value, setValue, maxLength }) {
    const [see, SetSee] = useState(false)
    return (
        <div style={{ width: width, height: height }} className='login-input-widget'>
            {widget}
            <input maxLength={maxLength} value={value} onChange={(e) => setValue(e.target.value)} placeholder={placeholder} className='input-login-style' type={type === 'password' ? see ? 'text' : 'password' : type} />
            <div onClick={() => SetSee(!see)} className='visibility'>
                {type === 'password' ? see ? <VisibilityIcon /> : <VisibilityOffIcon /> : <></>}
            </div>
        </div>
    )
}
