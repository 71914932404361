import { redirect } from "react-router-dom";
import { cookiesGlobal } from "../App";
import { showAlert } from "./show_alert";

function hundleRequestFailure(response) {
    if (response.statusCode === 401) {
        cookiesGlobal.remove('auth')
        cookiesGlobal.remove('email')
        redirect('/authentication', { replace: true });
        return;
      } else {
        showAlert(false, response.response);
      }
}
export { hundleRequestFailure }