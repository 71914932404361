import React from 'react'
import { extractVideoId } from '../../global/http_enum';
import { urlForFiles } from '../../global/http_url';
export default function FileReaderWidget({ file, type }) {
    if (type === 'image') {
        return <img src={urlForFiles(file)} alt="" />
    }
    if (type === 'audio') {
        return <audio src={urlForFiles(file)}></audio>
    }
    if (type === 'video') {
        return <video autoPlay={true} src={urlForFiles(file)}></video>
    }
    if (type === "link") {
        return <iframe title='link'  src={`https://www.youtube.com/embed/${extractVideoId(file)}`} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    }
    return (
        <></>
    )
}
