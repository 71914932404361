import { createSlice } from '@reduxjs/toolkit'
import { showAlert } from '../../functions/show_alert';
import { requestState } from '../../global/http_enum'
export const projectSlice = createSlice({
    name: 'projects',

    initialState: {
        requestState: requestState.initial,
        gridview: true,
        project: [],
        projectSelected: null,
    },
    reducers: {
        changeGridView: (state, actions) => {
            if (state.gridview) {
                state.gridview = false;
            } else {
                state.gridview = true;
            }
        },
        setProjectData: (state, actions) => {
            const { statusCode, response } = actions.payload;
            if (statusCode === 200) {
                state.project = response;
                state.requestState = requestState.haveData;
            } else if (statusCode === 401) {
                state.requestState = requestState.invalidTokne;
            }
            else if (statusCode > 500) {
                showAlert(false, response);
                state.requestState = requestState.serverError;
            } else {
                showAlert(false, response);
                state.requestState = requestState.internetConnection;
            }
        },
        AddProject: (state, actions) => {
            const { id, updated_at, name } = actions.payload;
            state.project = [...state.project, { id, name, event: null, problem: null, updated_at }];
        },
        requestStatusChanged: (state, action) => {
            state.requestState = requestState.initial;
            state.projectSelected = null
            state.project = [];
        },
        selectProject: (state, actions) => {
            state.projectSelected = actions.payload;
        },
        changeProjectEvent: (state, actions) => {
            const { id, event } = actions.payload;
            var projectTarget = state.project.findIndex((elem) => elem.id === id);
            if (projectTarget === -1) {
                return;
            }
            state.project[projectTarget] = { ...state.project[projectTarget], event: event };
            state.projectSelected = { ...state.projectSelected, event: event };
        },
        changeProjectProblem: (state, actions) => {
            const { id, problem } = actions.payload;
            const projectTargetIndex = state.project.findIndex((elem) => elem.id === id);

            if (projectTargetIndex === -1) {
                return; // Project not found, exit the function
            }

            // Check if the 'problem' property exists and is an array, if not, initialize it as an array
            if (!Array.isArray(state.project[projectTargetIndex].problem)) {
                state.project[projectTargetIndex].problem = [];
            }

            // Append the new problem to the existing list of problems
            state.project[projectTargetIndex].problem.push(problem);

            // Assuming state.projectSelected should also reflect this change if it's the same project
            if (state.projectSelected && state.projectSelected.id === id) {
                if (!Array.isArray(state.projectSelected.problem)) {
                    state.projectSelected.problem = [];
                }
                state.projectSelected.problem.push(problem);
            }
        },

        deleteProjectByID: (state, actions) => {
            if (state.projectSelected != null) {
                if (state.projectSelected.id === actions.payload) {
                    state.projectSelected = null
                }
            }
            state.project = state.project.filter(obj => obj.id !== actions.payload)
        },
        editProjectByID: (state, actions) => {
            const { id, projectName } = actions.payload;
            const updatedList = state.project.map(obj =>
                obj.id === id ? { ...obj, name: projectName } : obj
            );

            state.project = updatedList;
            if (state.projectSelected !== null && id === state.projectSelected.id) {
                state.projectSelected = { ...state.projectSelected, name: projectName }
            }
        },
        editProjectDomainByID: (state, actions) => {
            const { id, newDomain } = actions.payload;
            const updatedList = state.project.map(project =>
                // Check if this is the project to update
                project.id === id
                    ? {
                        ...project,
                        // Update the event object within the project
                        event: {
                            ...project.event,
                            domain: newDomain // Update the domain to the new value
                        }
                    }
                    : project // No change for other projects
            );

            // Update the project list in the state
            state.project = updatedList;

            // If the currently selected project is the one being updated, also update its domain
            if (state.projectSelected !== null && id === state.projectSelected.id) {
                state.projectSelected = {
                    ...state.projectSelected,
                    event: {
                        ...state.projectSelected.event,
                        domain: newDomain
                    }
                };
            }
        },
    }
});

export const { changeGridView, requestStatusChanged, setProjectData, AddProject, selectProject, changeProjectEvent, changeProjectProblem, deleteProjectByID, editProjectByID, editProjectDomainByID } = projectSlice.actions

export default projectSlice.reducer