import React from 'react'
import './create_button.css'
import AddCircleIcon from '@mui/icons-material/AddCircle';
export default function CreateButtonForAllScreen({ haveDomain, redMessage, buttonMessage, domain, onClick }) {
    return (
        <div id='create-button-all'>
            {haveDomain ?
                <div className="domain-name">
                    {domain}
                </div> : <></>
            }
            <div className="message-button">
                {redMessage}
            </div>
            <div onClick={() => onClick(false)} className={`button-to-tap ${ haveDomain ? '':'button-to-tap-big'}`}>
                <div className="button-title">
                    {buttonMessage}
                </div>
                <div className="icon-button">
                    <AddCircleIcon className='icon' />
                </div>
            </div>
        </div>
    )
}
