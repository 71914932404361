import React, { useState } from 'react'
import LoginInputWidget from '../../../widgets/input/login_input';
import './forgot_password.css'
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Link } from 'react-router-dom';
import { authenticationPage } from '../authentication';
import LockIcon from '@mui/icons-material/Lock';
import { confrimCode, newPasswordChange, resendVerificationCode, sendCodeToEmail } from '../../../api/auth';
import { showProgressAlert, HideProgressAlert } from '../../../functions/show_progress_page'
import { showAlert } from '../../../functions/show_alert';
export default function ForgotPassword({ SetPage }) {
  const [step, SetStep] = useState(0);
  const [email, SetEmail] = useState('');
  const [password, SetPassword] = useState('');
  const [rePassword, SetRePassword] = useState('');
  const [timer, SetTimer] = useState(30);
  const [timerInterval, setTimerInterval] = useState(null);
  const [verificationCode, SetVerificationCode] = useState('');
  const hundleSendEmail = async () => {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) {
      showAlert(false, 'Enter Valid Email');
      return;
    }
    showProgressAlert()
    const response = await sendCodeToEmail(email);
    HideProgressAlert();
    if (response.statusCode === 200) {
      SetStep(1);
      SetTimer(30);
      startTimer();
      showAlert(true, 'verification send successfully');
    } else {
      showAlert(false, response.response);
    }

  }
  const sendConfirmation = async () => {
    showProgressAlert()
    const response = await resendVerificationCode(email);
    HideProgressAlert()
    if (response.statusCode === 200) {
      clearInterval(timerInterval);
      SetTimer(30);
      startTimer();
    } else {
      showAlert(false, response.response);
    }
  };
  const tryConfirmCode = async () => {
    showProgressAlert()
    const response = await confrimCode(verificationCode, email);
    HideProgressAlert()
    if (response.statusCode === 200) {
      clearInterval(timerInterval);
      SetStep(2);
    } else {
      showAlert(false, response.response);
    }
  }
  const hundleNewPasswordType = async () => {
    if (password !== rePassword) {
      showAlert(false, 'Passwords and re-password do not match ');
      return;
    }
    if (password.length < 6) {
      showAlert(false, 'password is not strong enough');
      return;
    }
    showProgressAlert()
    const response = await newPasswordChange(password, email);
    HideProgressAlert()
    if (response.statusCode === 200) {
      SetPage(authenticationPage.login)
      clearInterval(timerInterval);
    } else {
      showAlert(false, response.response);
    }
  }

  const startTimer = () => {
    const interval = setInterval(() => { SetTimer((prevCountdown) => prevCountdown - 1) }, 1000);
    setTimerInterval(interval);
  };

  return (
    <div id='forgot-password'>
      <h1 className='forgot-box-title'>Forgot password</h1>
      {step === 0 ?
        <div className="step-holder">
          <h1 className='input-title'>Enter your Email</h1>
          <LoginInputWidget width={'297px'} height={'50px'} type={'text'} widget={<PersonIcon />} placeholder={'Enter a valid email address'} setValue={SetEmail} value={email} />
          <Link onClick={() => SetPage(authenticationPage.login)} className='back-link'>Back to sign in</Link>
          <div onClick={() => hundleSendEmail()} className="button">
            Send
          </div>
        </div>
        : <></>
      }
      {step === 1 ?
        <div className="step-holder">
          <h1 className='input-title'>Confirm verification code</h1>
          <LoginInputWidget width={'297px'} height={'50px'} type={'text'} widget={<VpnKeyIcon />} placeholder={'verification code'} setValue={SetVerificationCode} value={verificationCode} />
          <Link className='back-link'>If you did’t recieve <span onClick={(e) => { e.preventDefault(); if (timer <= 0) { sendConfirmation() } }} className='resend'> {timer <= 0 ? 'Resend' : 'Resend after ' + timer}</span></Link>
          <div onClick={() => tryConfirmCode()} className="button">
            Send
          </div>
        </div>
        : <></>
      }
      {step === 2 ? <div className="step-holder">
        <LoginInputWidget width={'297px'} height={'50px'} type={'text'} widget={<LockIcon />} placeholder={'Password'} setValue={SetPassword} value={password} />
        <div className="space"></div>
        <LoginInputWidget width={'297px'} height={'50px'} type={'text'} widget={<LockIcon />} placeholder={'Re-password'} setValue={SetRePassword} value={rePassword} />
        <div onClick={() => hundleNewPasswordType()} className="button">
          Submit
        </div>
      </div>
        : <></>
      }
    </div>
  )
}
