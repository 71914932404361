import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import './list_project.css';
import { convertDay } from '../../../functions/date_converter';
import { useNavigate } from 'react-router-dom';
import { shortenText } from '../../../global/http_enum';
export default function ListProject({ projectData, hundleSelectProject, Selected, SetEditProject, deleteProject }) {
    const navigate = useNavigate();
    return (
        <div onClick={() => hundleSelectProject(projectData)} onDoubleClick={() => navigate('/event')} className={`project-list ${Selected === projectData.id ? 'project-list-selected' : ''}`}>
            <div className="name-and-date">
                <div className='event-name'>{projectData.name}</div>
                <div className='event-time'>{convertDay(projectData.updated_at)}</div>
            </div>

            <div className="informations">
                <div>Event : {projectData.event ? shortenText(projectData.event.name, 40) : '...'}</div>
                <div>Problem : <span className='redt'>{projectData.event ? shortenText(projectData.event.problem_identifyed_from_event, 40) : '...'}</span> </div>
                <div>Negative Function : <span className='redt'>{projectData.event ? shortenText(projectData.event.negative_function_exluded, 40) : '...'}</span></div>
            </div>
            <div className="actions">
                <div className='main-text-list'>
                    <DeleteIcon onClick={() => deleteProject(projectData.id)} className='delete' />
                    <NoteAltIcon onClick={() => SetEditProject(projectData.id)} className='edite' />
                </div>
            </div>
        </div>
    )
}
