import React from 'react'
import AppBarLoginPNG from '../../../assets/logo.png';
import './app_bar_login.css'
export default function AppBarLogin() {
    return (
        <div id='app_bar_login'>
            <img src={AppBarLoginPNG} alt="" />
        </div>
    )
}
