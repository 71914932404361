import { Route, Routes, BrowserRouter } from "react-router-dom";
import AlertWidget from "./widgets/alert/alert";
import './css/main_css.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Cookies } from "react-cookie";
import ProjectScreen from "./screens/projects_screen/project_screen";
import PosterScreen from "./screens/posters/poster";
import EventScreen from "./screens/event_screen/event_screen";
import ProblemScreen from "./screens/problem_screen/problem_screen";
import SolutionScreen from "./screens/solution_screen/solution_screen";
import ReportScreen from "./screens/report_screen/report_screen";
import NoPage from "./screens/no_page/no_page";
import AuthenticationPage from "./screens/auth/authentication";
import AuthGuarder from "./auth_garder/auth_guard";
import LogicGuard from "./auth_garder/logic_guard";
import PageCircleProgress from "./widgets/page_circle_progress/page_circle_progress";
import './css/main_css.css';
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/authentication" element={<AuthenticationPage />}></Route>
          <Route element={<AuthGuarder />}>
            <Route path="/posters" element={<PosterScreen />}></Route>
            <Route path="/" element={<ProjectScreen />}></Route>
            <Route element={<LogicGuard />}>
              <Route path="/event" element={<EventScreen />}></Route>
              <Route path="/problem" element={<ProblemScreen />}></Route>
              <Route path="/solution" element={<SolutionScreen />}></Route>
              <Route path="/report" element={<ReportScreen />}></Route>
            </Route>
          </Route>
          {/* <Route element={<AuthGuarder />}>
        <Route path="/" element={<ProjectScreen />}></Route>
        <Route element={<LogicGuard />}>
          <Route path="/sentence" element={<SentenceScreen />}></Route>
          <Route path="/verifications" element={<VerificationsScreen />}></Route>
          <Route path="/identify-entity" element={<IdentifyEntity />}></Route>
          <Route path="/flow" element={<FlowScreen />}></Route>
          <Route path="/relationship" element={<RelationShipScreen />}></Route>
          <Route path="/report" element={<ReportScreen />}></Route>
        </Route>
      </Route> */}
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
      <AlertWidget />
      <PageCircleProgress />
    </div>

  );
}
const cookiesGlobal = new Cookies();
export { cookiesGlobal }
export default App;

