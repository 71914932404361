import React from 'react'
import './app_bar.css';
import Logo from '../../assets/logo.png'
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { useDispatch } from 'react-redux';
import { requestStatusChanged } from '../../redux/project/projects';
import { cookiesGlobal } from '../../App';
export default function AppBarWidget() {
    const dispatch = useDispatch();
    const [drawer, SetDrawer] = useState(false)
    return (
        <div className='app_bar_holder'>
            <div id='desktop_appbar' className="app_bar_holder">
                <div className='main_app_bar'>
                    <div className="header_logo">
                        <img src={Logo} alt="_" />
                    </div>
                    <div className="header_links">
                        <Link className='inter-14' to="/posters" ><h1 className='link-header inter-14' >Posters <span></span></h1></Link>
                        <Link className='inter-14' to="/" ><h1 className='link-header inter-14' >Project <span></span></h1></Link>
                        <Link className='inter-14' to="/event" ><h1 className='link-header inter-14' >Event <span></span></h1></Link>
                        <Link className='inter-14' to="/problem" ><h1 className='link-header inter-14' >Problem<span></span> </h1></Link>
                        <Link className='inter-14' to="/solution" ><h1 className='link-header inter-14' >Solution <span></span></h1></Link>
                        <Link className='inter-14' to="/report" ><h1 className='link-header inter-14'>Report<span></span></h1></Link>
                        <Link className='inter-14' onClick={() => { cookiesGlobal.remove('auth'); dispatch(requestStatusChanged()) }} to="/authentication" ><h1 className='link-header inter-14'>logout</h1></Link>
                    </div>
                </div>
                <div className="app_bar_divider"></div>
            </div>
            <div id='mobile_appbar' className="app_bar_holder">
                <div className='main_app_bar'>
                    <div className='menu-icons'>
                        <MenuIcon onClick={() => SetDrawer(true)} />
                    </div>
                    <div className="header_logo">
                        <img src={Logo} alt="_" />
                    </div>
                </div>
                <div className={`menu-mobile ${!drawer ? 'menu-mobile-hide' : ''}`}>
                    <div className="user-name-section">
                        <CloseIcon onClick={() => SetDrawer(false)} className='close-button' />
                        <PersonPinIcon className='person-icon' />
                        <h1 className='username-drawer'>{cookiesGlobal.get('email').split('@')[0].replace('.', ' ') ?? ' user'}</h1>
                    </div>
                    <div className="links-app-bar">
                    <Link className='inter-14' to="/posters" ><h1 className='link-header inter-14' >Posters <span></span></h1></Link>
                        <Link className='inter-14' to="/" ><h1 className='link-header inter-14' >Project <span></span></h1></Link>
                        <Link className='inter-14' to="/event" ><h1 className='link-header inter-14' >Event <span></span></h1></Link>
                        <Link className='inter-14' to="/problem" ><h1 className='link-header inter-14' >Problem<span></span> </h1></Link>
                        <Link className='inter-14' to="/solution" ><h1 className='link-header inter-14' >Solution <span></span></h1></Link>
                        <Link className='inter-14' to="/report" ><h1 className='link-header inter-14'>Report<span></span></h1></Link>
                        <Link className='inter-14' onClick={() => { cookiesGlobal.remove('auth'); dispatch(requestStatusChanged()) }} to="/authentication" ><h1 className='link-header inter-14'>logout</h1></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
