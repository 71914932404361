// the given set images
import TheGivenSetCommunicationDomain from '../../assets/Posters/The Given Set/The Given Set Communication Domain.jpg'
import TheGivenSetTheoryDomainB from '../../assets/Posters/The Given Set/The Given set theory domain B.jpg'
// The Error Correction
import TheErrorCorrectionFunction from '../../assets/Posters/The Error Correction Function/The Error Correction Function.png'
import ErrorCorrectionCommunicationDomainA from '../../assets/Posters/The Error Correction Function/Error Correction Communication Domain_A.jpg'
// The Given Set Stability
import GivenStabilityPerson1Person2 from '../../assets/Posters/The Given Set Stability/Given Stability Person 1 Person 2.jpg';
import GivenSetStabilityonTopandBelow from '../../assets/Posters/The Given Set Stability/Given Set Stability on Top and Below.jpg';
import GivenSetStabilityMeYouWe from '../../assets/Posters/The Given Set Stability/Given Set Stability Me You We.jpg';
import GivenSetStabilityMeYouWeBelow from '../../assets/Posters/The Given Set Stability/Given Set Stability Me You We Below.jpg';
import GivenSetStablityPrincipleFlowPerson1Person2 from '../../assets/Posters/The Given Set Stability/Given Set Stablity Principle Flow Person 1 Person 2.jpg';
import GivenSetStabilityonTopBelowPrincipleFlow from '../../assets/Posters/The Given Set Stability/Given Set Stability on Top Below Principle Flow.jpg';
import GivenSetStabilityPrincipleFlowFromToptoBelowMeYou from '../../assets/Posters/The Given Set Stability/Given Set Stability Principle Flow from Top to Below Me You.jpg';
import GivenSetStabilityPrincipleFlowMeYou from '../../assets/Posters/The Given Set Stability/Given Set Stability Principle Flow Me You.jpg';


// Problem Identification and Solution
import ProblemSolutionsCommunicationDomain from '../../assets/Posters/Problem Identification and Solution/Problem & Solutions Communication Domain_ B.jpg'
import ProblemSolutionsTheoryDomainB from '../../assets/Posters/Problem Identification and Solution/Problem & Solutions Theory Domain B.jpg'

// The Given Solution
import SolutionProcessWorkinginGroup1 from '../../assets/Posters/The Given Solution/Solution Process Working in Group 1.jpg'
import SolutionProcessWorkinginGroupMath from '../../assets/Posters/The Given Solution/Solution Process Working in Group Math 1.jpg'
import TheGivenSolutionApplication from '../../assets/Posters/The Given Solution/the given solution application.png'
import TheGivenSolutionApplicationIdea from '../../assets/Posters/The Given Solution/the given solution application idea.png'
import TheGivenSolutionList from '../../assets/Posters/The Given Solution/the given solution list.png'


// contry map
import USAasFunctonMainLand from '../../assets/Posters/Country Map/USA as functon main land.png'
import USAasFunctionMainLandPositiveAndNegative from '../../assets/Posters/Country Map/USA as function main land positive and negative.png'
import USAasFunctionPeopleAndFunction from '../../assets/Posters/Country Map/USA as function people and function.png'
import USAasFunctionPeopleAndFunctionPositiveAndNegative from '../../assets/Posters/Country Map/USA as function people and function positive and negative.png'
import USAasFunctionAlaskaNaHawaii from '../../assets/Posters/Country Map/USA as function alaska na hawaii.png'
import USAasFunctionAlaskaHawaiiPositiveAndNegative from '../../assets/Posters/Country Map/USA as function alaska hawaii positive and negative.png'
import USAasFunctionAlaskaAndHawaiiWithPeople from '../../assets/Posters/Country Map/USA as function alaska and hawaii with people.png'
import USAasFunctionWithPeopleAlaskaAndHawaitPositiveAndNegative from '../../assets/Posters/Country Map/USA as function with people alaska and hawait positive and negative.png'
import USAasHouse from '../../assets/Posters/Country Map/USA as house.png'
import USAasBoatOfFunction from '../../assets/Posters/Country Map/USA as boat of function.png'
import USAAsboatOfFunctionPositiveAndNegative from '../../assets/Posters/Country Map/USA as boat of function positive and negative.png'
import USAasBoatOfFunctionWithPeople from '../../assets/Posters/Country Map/USA as boat of function with people.png'
import USAasBoatOfFunctionWithPeoplePositiveAndNegative from '../../assets/Posters/Country Map/USA as boat of function with people positive and negative.png'




import React, { useState } from 'react'
import './poster.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PagesTitle from '../../widgets/pages_title/pages_title';

import AppBarWidget from '../../widgets/app_bar/app_bar';
const type = {
    theGivenSet: 'The Given Set',
    theErrorCorrection: 'Error Correction',
    theGivenSetStability: 'The Given Set Stability',
    problem: 'Problem Identification and Solution',
    solution: 'The Given Solution',
    contry: 'Country Map',
    none: 'none',
}
export default function PosterScreen() {

    const [posterType, SetPosterType] = useState(type.none);
    const [showThisPoster, SetShowThisPoster] = useState(null);
    const images = {
        'The Given Set': [
            {
                'name': "The Given Set Communication Domain",
                'image': TheGivenSetCommunicationDomain
            },
            {
                'name': "The Given set theory domain",
                'image': TheGivenSetTheoryDomainB,
            },
        ],
        'Error Correction': [
            {
                'name': "The Error Correction Function",
                'image': TheErrorCorrectionFunction,
            },
            {
                'name': "Error Correction Communication Domain",
                'image': ErrorCorrectionCommunicationDomainA,
            },
        ],
        'The Given Set Stability': [
            {
                'name': "Given Stability Person 1 Person 2",
                'image': GivenStabilityPerson1Person2,
            },
            {
                'name': "Given Set Stability on Top and Below",
                'image': GivenSetStabilityonTopandBelow,
            },
            {
                'name': "Given Set Stability Me You We",
                'image': GivenSetStabilityMeYouWe,
            },
            {
                'name': "Given Set Stability Me You We Below",
                'image': GivenSetStabilityMeYouWeBelow,
            },
            {
                'name': "Given Set Stablity Principle Flow Person 1 Person 2",
                'image': GivenSetStablityPrincipleFlowPerson1Person2,
            },
            {
                'name': "Given Set Stability on Top Below Principle Flow",
                'image': GivenSetStabilityonTopBelowPrincipleFlow,
            },
            {
                'name': " Given Set Stability Principle Flow from Top to Below Me You",
                'image': GivenSetStabilityPrincipleFlowFromToptoBelowMeYou,
            },
            {
                'name': "Given Set Stability Principle Flow Me You",
                'image': GivenSetStabilityPrincipleFlowMeYou,
            },
        ],
        'Problem Identification and Solution': [
            {
                'name': "Problem & Solutions Communication Domain",
                'image': ProblemSolutionsCommunicationDomain,
            },
            {
                'name': "Problem & Solutions Theory Domain",
                'image': ProblemSolutionsTheoryDomainB,
            },

        ],
        'The Given Solution': [
            {
                'name': "Solution Process Working in Group 1",
                'image': SolutionProcessWorkinginGroup1,
            },
            {
                'name': "Solution Process Working in Group Math 1",
                'image': SolutionProcessWorkinginGroupMath,
            },
            {
                'name': "The Given Solution Application",
                'image': TheGivenSolutionApplication,
            },
            {
                'name': "The Given Solution Application Idea",
                'image': TheGivenSolutionApplicationIdea,
            },
            {
                'name': "The Given Solution List",
                'image': TheGivenSolutionList,
            },
        ],
        'Country Map': [
            {
                'name': "USA as functon main land",
                'image': USAasFunctonMainLand
            },
            {
                'name': "USA as function main land positive and negative",
                'image': USAasFunctionMainLandPositiveAndNegative
            },
            {
                'name': "USA as function people and function",
                'image': USAasFunctionPeopleAndFunction
            },
            {
                'name': "USA as function people and function positive and negative",
                'image': USAasFunctionPeopleAndFunctionPositiveAndNegative
            },
            {
                'name': "USA as function alaska na hawaii",
                'image': USAasFunctionAlaskaNaHawaii
            },
            {
                'name': "USA as function alaska hawaii positive and negative",
                'image': USAasFunctionAlaskaHawaiiPositiveAndNegative
            },
            {
                'name': "USA as function alaska and hawaii with people",
                'image': USAasFunctionAlaskaAndHawaiiWithPeople,
            },
            {
                'name': "USA as function with people alaska and hawait positive and negative",
                'image': USAasFunctionWithPeopleAlaskaAndHawaitPositiveAndNegative,
            },
            {
                'name': "USA as house",
                'image': USAasHouse,
            },
            {
                'name': "USA as boat of function",
                'image': USAasBoatOfFunction,
            },
            {
                'name': "USA as boat of function positive and negative",
                'image': USAAsboatOfFunctionPositiveAndNegative,
            },
            {
                'name': "USA as boat of function with people",
                'image': USAasBoatOfFunctionWithPeople,
            },
            {
                'name': "USA as boat of function with people positive and negative",
                'image': USAasBoatOfFunctionWithPeoplePositiveAndNegative,
            },
        ],

    }
    return (
        <>
            <AppBarWidget />
            <div id='poster'>
                <div className="title-holder">
                    <PagesTitle title={`${posterType !== type.none ? 'Poster ' + posterType : 'Poster Category'}`} />
                </div>
                {posterType !== type.none ? <div onClick={() => SetPosterType(type.none)} className='back-button'><ArrowBackIosIcon /> BACK</div> : <></>}
                {
                    posterType === type.none ?
                        <div className='types-holder'>
                            <div onClick={() => SetPosterType(type.theGivenSet)} className='main-box-holder'>
                                <div className="type-box"><img src={TheGivenSetCommunicationDomain} alt="" /></div>
                                <h1 className='box-type'>The Given Set</h1>
                            </div>
                            <div onClick={() => SetPosterType(type.theErrorCorrection)} className='main-box-holder'>
                                <div className="type-box"><img src={TheErrorCorrectionFunction} alt="" /></div>
                                <h1 className='box-type'>Error Correction</h1>
                            </div>
                            <div onClick={() => SetPosterType(type.theGivenSetStability)} className='main-box-holder'>
                                <div className="type-box"><img src={GivenStabilityPerson1Person2} alt="" /></div>
                                <h1 className='box-type'>The Given Set Stability</h1>
                            </div>
                            <div onClick={() => SetPosterType(type.solution)} className='main-box-holder'>
                                <div className="type-box"><img src={SolutionProcessWorkinginGroup1} alt="" /></div>
                                <h1 className='box-type'>The Given Solution</h1>
                            </div>
                            <div onClick={() => SetPosterType(type.problem)} className='main-box-holder'>
                                <div className="type-box"><img src={ProblemSolutionsCommunicationDomain} alt="" /></div>
                                <h1 className='box-type'>Problem Identification and Solution</h1>
                            </div>
                            <div onClick={() => SetPosterType(type.contry)} className='main-box-holder'>
                                <div className="type-box"><img src={USAasFunctonMainLand} alt="" /></div>
                                <h1 className='box-type'>Country Map</h1>
                            </div>
                            {/* <div onClick={() => SetPosterType(type.none)} className="type-box flex">
                                <AddCircleIcon className='add-poster' />
                                <h1 className='create-poster'>Create Poster</h1>
                            </div> */}
                        </div>
                        :
                        <div className="posters">
                            {images[posterType].map((e, index) => <PosterElement key={index} SetShowThisPoster={SetShowThisPoster} urlLink={e.image} name={e.name} />)}
                        </div>
                }
                {showThisPoster === null ? <></> : <div onClick={() => SetShowThisPoster(null)} className="poster-big">
                    <img src={showThisPoster} alt=" " />
                </div>}
            </div>
        </>

    )
}

const PosterElement = ({ urlLink, name, SetShowThisPoster }) => {
    return <div onClick={() => SetShowThisPoster(urlLink)} className="poster-element">
        <img alt=" " src={urlLink} className="poster-image"></img>
        <div className="proster-element-name">{name}</div>
    </div>
}


