import React from 'react'
import './no_page.css'
import NoPageImage from '../../assets/404.png'
import { useNavigate } from 'react-router-dom'
export default function NoPage() {
  const navigate = useNavigate();
  return (
    <div id='page-not-found'>
      <img alt=' ' src={NoPageImage} className="image" />
      <div className="text">{'the page that you are looking for does not exist'.toLocaleUpperCase()}</div>
      <div onClick={() => navigate('/')} className="button">Project Page</div>
    </div>
  )
}
