import React, { useState } from 'react'
import InputInfo from '../../../widgets/input_info/input_info';
import './create_problem.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import { CreateProblem } from '../../../api/problem';
import { showAlert } from '../../../functions/show_alert';
import { hundleRequestFailure } from '../../../functions/hundle_request_failure';
import CreateButtonForAllScreen from '../../../widgets/create_button/create_button';
import { useDispatch } from 'react-redux';
import { changeProjectProblem } from '../../../redux/project/projects';
import { HideProgressAlert, showProgressAlert } from '../../../functions/show_progress_page';
import { SortProblemList } from '../../../functions/problem_sorting';
export default function CreateProblemWidget({ projectSelected }) {
    const dispatch = useDispatch();
    const [pickedPD, SetPickedPD] = useState([]);
    const [pickePMT, SetPickedPMT] = useState([]);
    const [showPD, SetPD] = useState(false);
    const [showPMT, SetPMT] = useState(false);
    const [showCreateButton, SetCreateProblem] = useState(true);
    const hundleCreateProblem = async () => {
        if (pickedPD.length === 0) {
            showAlert(false, 'pick Pricipal defeciency')
            return;
        }
        if (pickedPD.length === 0) {
            showAlert(false, 'pick Problem Mapped To')
            return;
        }
        const data = SortProblemList(pickePMT, projectSelected.event.domain);
        showProgressAlert();
        const response = await CreateProblem(projectSelected.id, pickedPD, data, projectSelected.event.domain);
        HideProgressAlert();
        if (response.statusCode < 300) {
            showAlert(true, 'Problem Created');
            dispatch(changeProjectProblem({ id: projectSelected.id, problem: response.response }))
        } else {
            hundleRequestFailure(response);
        }
    }
    if (showCreateButton) {
        return <CreateButtonForAllScreen buttonMessage={'Event to problem mapping'} domain={''} haveDomain={false} onClick={SetCreateProblem} redMessage={'You have not map the event to a problem yet, tap map event to a problem'} />;
    } else {
        return <>
            <div id="create-problem-widget">
                <InputInfo name={'Event name'} onClick={() => { }} onValueChanged={() => { }} readOnly={false} value={projectSelected.event.name} />
                <InputInfo name={'Negative Function Executed'} onClick={() => { }} onValueChanged={() => { }} readOnly={false} value={projectSelected.event.negative_function_exluded} />
                <h1 className='dropdonw-title'>Pricipal defeciency</h1>
                <div className={`dropdown-holer ${showPD ? 'dropdown-holder-show' : ''}`}>
                    <ExpandMoreIcon onClick={() => SetPD(!showPD)} className='icon' />
                    <div className='element-to-pick'>
                        <div onClick={() => SetPickedPD(state => [...state, 'The Communication Set'])} className='itme-to-pick' >The Communication Set <span>{pickedPD.includes('The Communication Set') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                        <div onClick={() => SetPickedPD(state => [...state, 'The Information Set'])} className='itme-to-pick' >The Information Set  <span>{pickedPD.includes('The Information Set') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                        <div onClick={() => SetPickedPD(state => [...state, 'The Instrumentation Set'])} className='itme-to-pick' >The Instrumentation Set  <span>{pickedPD.includes('The Instrumentation Set') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                        <div onClick={() => SetPickedPD(state => [...state, 'The Marketing Set'])} className='itme-to-pick' >The Marketing Set  <span>{pickedPD.includes('The Marketing Set') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                        <div onClick={() => SetPickedPD(state => [...state, 'The Exchange Set'])} className='itme-to-pick' >The Exchange Set  <span>{pickedPD.includes('The Exchange Set') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                        <div onClick={() => SetPickedPD(state => [...state, 'The Gaming Set'])} className='itme-to-pick' >The Gaming Set  <span>{pickedPD.includes('The Gaming Set') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                        <div onClick={() => SetPickedPD(state => [...state, 'The Work Set'])} className='itme-to-pick' >The Work Set   <span>{pickedPD.includes('The Work Set') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                        <div onClick={() => SetPickedPD([])} className='clear-item' >Clear </div>
                    </div>
                </div>
                <h1 className='dropdonw-title'>Problem Mapped To</h1>
                <div className={`dropdown-holer ${showPMT ? projectSelected.event.domain === 'Theory' ? 'dropdown-holder-show-pmt' : 'comunication' : ''}  `}>
                    <ExpandMoreIcon onClick={() => SetPMT(!showPMT)} className='icon' />
                    <div className='element-to-pick'>
                        {projectSelected.event.domain === 'Theory' ?
                            <>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Disregarding Application of Theory'])} className='itme-to-pick' >Disregarding Application of Theory<span>{pickePMT.includes('Disregarding Application of Theory') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Misinterpretation of Theory'])} className='itme-to-pick' >Misinterpretation of Theory  <span>{pickePMT.includes('Misinterpretation of Theory') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Error in Presentation of Theory or Improper Presentation of Theory'])} className='itme-to-pick' >Error in Presentation of Theory or Improper Presentation of Theory<span>{pickePMT.includes('Error in Presentation of Theory or Improper Presentation of Theory') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Disregard Importance of Theory'])} className='itme-to-pick' >Disregard Importance of Theory <span>{pickePMT.includes('Disregard Importance of Theory') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Disregard Relationship of Theory and Theory of Communication'])} className='itme-to-pick' > Disregard Relationship of Theory and Theory of Communication<span>{pickePMT.includes('Disregard Relationship of Theory and Theory of Communication') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Disregard Portability of Theory'])} className='itme-to-pick' >Disregard Portability of Theory  <span>{pickePMT.includes('Disregard Portability of Theory') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Disregard Theory and System Relationship'])} className='itme-to-pick' >Disregard Theory and System Relationship <span>{pickePMT.includes('Disregard Theory and System Relationship') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Disregard Independency of Theory'])} className='itme-to-pick' >Disregard Independency of Theory <span>{pickePMT.includes('Disregard Independency of Theory') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Expandability of Philosophy'])} className='itme-to-pick' >Expandability of Philosophy   <span>{pickePMT.includes('Expandability of Philosophy') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Misapplication of Instruments'])} className='itme-to-pick' >Misapplication of Instruments <span>{pickePMT.includes('Misapplication of Instruments') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Misuse of Good Instruments'])} className='itme-to-pick' >Misuse of Good Instruments   <span>{pickePMT.includes('Misuse of Good Instruments') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Disregard Instrument and System Relationship'])} className='itme-to-pick' >Disregard Instrument and System Relationship <span>{pickePMT.includes('Disregard Instrument and System Relationship') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Disregard System and System Relationship'])} className='itme-to-pick' >Disregard System and System Relationship   <span>{pickePMT.includes('Disregard System and System Relationship') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Disregard Function and System Relationship'])} className='itme-to-pick' >Disregard Function and System Relationship <span>{pickePMT.includes('Disregard Function and System Relationship') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Misapplication of Method'])} className='itme-to-pick' >Misapplication of Method   <span>{pickePMT.includes('Misapplication of Method') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Application of Bad Methods'])} className='itme-to-pick' >Application of Bad Methods   <span>{pickePMT.includes('Application of Bad Methods') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Disregard Method and System Relationship'])} className='itme-to-pick' >Disregard Method and System Relationship   <span>{pickePMT.includes('Disregard Method and System Relationship') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT([])} className='clear-item' >Clear </div>
                            </> :
                            <>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Perform inappropriate actions'])} className='itme-to-pick' >Perform inappropriate actions<span>{pickePMT.includes('Perform inappropriate actions') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Communicate about performing inappropriate actions'])} className='itme-to-pick' >Communicate about performing inappropriate actions <span>{pickePMT.includes('Communicate about performing inappropriate actions') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Misuse of instructions'])} className='itme-to-pick' >Misuse of instructions<span>{pickePMT.includes('Misuse of instructions') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Communicate about misusing of instructions'])} className='itme-to-pick' >Communicate about misusing of instructions <span>{pickePMT.includes('Communicate about misusing of instructions') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Use inappropriate instructions'])} className='itme-to-pick' > Use inappropriate instructions<span>{pickePMT.includes('Use inappropriate instructions') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Communicate about using inappropriate instructions'])} className='itme-to-pick' >Communicate about using inappropriate instructions<span>{pickePMT.includes('Communicate about using inappropriate instructions') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Disregard instructions'])} className='itme-to-pick' >Disregard instructions <span>{pickePMT.includes('Disregard instructions') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Communicate about disregarding instructions'])} className='itme-to-pick' >Communicate about disregarding instructions <span>{pickePMT.includes('Communicate about disregarding instructions') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Misuse of objects'])} className='itme-to-pick' >Misuse of objects   <span>{pickePMT.includes('Misuse of objects') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Communicate about misuse of objects'])} className='itme-to-pick' >Communicate about misuse of objects <span>{pickePMT.includes('Communicate about misuse of objects') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Use inappropriate objects'])} className='itme-to-pick' >Use inappropriate objects   <span>{pickePMT.includes('Use inappropriate objects') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Communicate about using inappropriate objects'])} className='itme-to-pick' >Communicate about using inappropriate objects <span>{pickePMT.includes('Communicate about using inappropriate objects') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Misinformation'])} className='itme-to-pick' >Misinformation  <span>{pickePMT.includes('Misinformation') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Communicate about misinformation'])} className='itme-to-pick' >Communicate about misinformation <span>{pickePMT.includes('Communicate about misinformation') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Misinterpretation'])} className='itme-to-pick' >Misinterpretation <span>{pickePMT.includes('Misinterpretation') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Communicate about Misinterpretation'])} className='itme-to-pick' >Communicate about Misinterpretation  <span>{pickePMT.includes('Communicate about Misinterpretation') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Follow others'])} className='itme-to-pick' >Follow others  <span>{pickePMT.includes('Follow others') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Communicate about following others'])} className='itme-to-pick' >Communicate about following others  <span>{pickePMT.includes('Communicate about following others') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Miscommunication'])} className='itme-to-pick' >Miscommunication <span>{pickePMT.includes('Miscommunication') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Communicate about miscommunication'])} className='itme-to-pick' >Communicate about miscommunication <span>{pickePMT.includes('Communicate about miscommunication') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Exercise of force'])} className='itme-to-pick' >Exercise of force <span>{pickePMT.includes('Exercise of force') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Communicate about exercising of force'])} className='itme-to-pick' >Communicate about exercising of force <span>{pickePMT.includes('Communicate about exercising of force') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Disregard principles'])} className='itme-to-pick' >Disregard principles <span>{pickePMT.includes('Disregard principles') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Communicate about disregarding principles'])} className='itme-to-pick' >Communicate about disregarding principles <span>{pickePMT.includes('Communicate about disregarding principles') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Use of bad words'])} className='itme-to-pick' >Use of bad words <span>{pickePMT.includes('Use of bad words') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Use of bad expressions'])} className='itme-to-pick' >Use of bad expressions <span>{pickePMT.includes('Use of bad expressions') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Use of non-portable words'])} className='itme-to-pick' >Use of non-portable words <span>{pickePMT.includes('Use of non-portable words') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Use of non-portable expressions'])} className='itme-to-pick' >Use of non-portable expressions <span>{pickePMT.includes('Use of non-portable expressions') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Use of words with multiple meanings1'])} className='itme-to-pick' >Use of words with multiple meanings <span>{pickePMT.includes('Use of words with multiple meanings1') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Use of words with multiple meanings2'])} className='itme-to-pick' >Use of words with multiple meanings <span>{pickePMT.includes('Use of words with multiple meanings2') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Word matching error to form expression'])} className='itme-to-pick' >Word matching error to form expression <span>{pickePMT.includes('Word matching error to form expression') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Word does not match in sentence'])} className='itme-to-pick' >Word does not match in sentence <span>{pickePMT.includes('Word does not match in sentence') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Words do not identify actual entity'])} className='itme-to-pick' >Words do not identify actual entity <span>{pickePMT.includes('Words do not identify actual entity') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT(state => [...state, 'Provide misinformation'])} className='itme-to-pick' >Provide misinformation <span>{pickePMT.includes('Provide misinformation') ? <CheckIcon className='icon-on' /> : <></>}</span></div>
                                <div onClick={() => SetPickedPMT([])} className='clear-item' >Clear </div>
                            </>
                        }

                    </div>
                </div>
            </div>
            <div onClick={() => hundleCreateProblem()} className="button">Create</div></>
    }
}
