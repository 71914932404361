const showAlert = (failed, msg) => {
    const alertWidget = document.getElementById('alert-messages');
    // Set alert background color based on success
    if(!alertWidget){
        return;
    }
    alertWidget.style.backgroundColor = failed ? 'green' : 'red';

    // Set alert text
    alertWidget.innerText = msg;

    // Display alert
    alertWidget.style.display = 'block';

    // Hide alert after 3 seconds
    setTimeout(function () {
        alertWidget.style.display = 'none';
    }, 3000);
}
export { showAlert };