import SolutionRegardApplicationofTheory from '../assets/solution/Solution Regard Application of Theory.png'
import SolutionGoodInterpretationofTheory from '../assets/solution/Solution Good Interpretation of Theory.png'
import SolutionProperPresentationofTheory1 from '../assets/solution/Solution Proper Presentation of Theory 1.png'
import SolutionRegardImportanceofTheory from '../assets/solution/Solution Regard Importance of Theory.png'
import SolutionApplyTheoryofCommunicationRelationship from '../assets/solution/Solution Apply Theory of Communication Relationship.png'
import SolutionApplyPortabilityofTheory from '../assets/solution/Solution Apply Portability of Theory.png'
import SolutionApplyTheoryandSystemRelationship from '../assets/solution/Solution Apply Theory and System Relationship.png'
import SolutionApplyIndependencyofTheory from '../assets/solution/Solution Apply Independency of Theory.png'
import SolutionExpandabilityofTheoryThroughAplication from '../assets/solution/Solution Expandability of Theory Through Aplication.png'
import SolutionGoodUseofInstruments from '../assets/solution/Solution Good Use of Instruments.png'
import SolutionApplyInstrumentandSystemRelationship from '../assets/solution/Solution Apply Instrument and System Relationship.png'
import SolutionApplySystemandSystemRelationship from '../assets/solution/Solution Apply System and System Relationship.png'
import SolutionApplyFunctionandSystemRelationship from '../assets/solution/Solution Apply Function and System Relationship.png'
import SolutionGoodUsageofMethods from '../assets/solution/Solution Good Usage of Methods.png'
import SolutionApplicationofGoodMethods from '../assets/solution/Solution Application of Good Methods.png'
import SolutionApplyMethodandSystemRelationship from '../assets/solution/Solution Apply Method and System Relationship.png'
import { domainType } from '../global/http_enum'
// comunication
import PerformAppropriateActions from '../assets/solution2/Perform Appropriate Actions.png'
import CommunicateAboutperformingAppropriateActions from '../assets/solution2/Communicate about performing Appropriate Actions.png'
import GoodUsageofInstructions from '../assets/solution2/Good Usage of Instructions.png'
import CommunicateaboutGoodUsageofInstructions from '../assets/solution2/Communicate about Good Usage of Instructions.png'
import UseAppropriateInstructions from '../assets/solution2/Use Appropriate Instructions.png'
import CommunicateaboutusingAppropriateInstructions from '../assets/solution2/Communicate about using Appropriate Instructions.png'
import FollowInstructions from '../assets/solution2/Follow Instructions.png'
import CommunicateaboutFollowingInstructions from '../assets/solution2/Communicate about Following Instructions.png'
import GoodUseofObjects from '../assets/solution2/Good Use of Objects.png'
import CommunicateaboutGoodUseofObjects from '../assets/solution2/Communicate about Good Use of Objects.png'
import UseAppropriateObjects1 from '../assets/solution2/Use Appropriate Objects 1.png'
import CommunicateaboutusingAppropriateObjects1 from '../assets/solution2/Communicate about using Appropriate Objects 1.png'
import ProperInformation from '../assets/solution2/Proper Information.png'
import CommunicateaboutProperInformation from '../assets/solution2/Communicate about Proper Information.png'
import GoodInterpretation from '../assets/solution2/Good Interpretation.png'
import CommunicateaboutGoodInterpretation from '../assets/solution2/Communicate about Good Interpretation.png'
import FollowPrinciples from '../assets/solution2/Follow Principles.png'
import CommunicateAboutFollowingPrinciple from '../assets/solution2/Communicate About Following Principle.png'
import ProperCommunication from '../assets/solution2/Proper Communication.png'
import CommunicateaboutProperCommunication from '../assets/solution2/Communicate about Proper Communication.png'
import ExerciseofKindness from '../assets/solution2/Exercise of Kindness.png'
import CommunicateaboutExercisingofKindness from '../assets/solution2/Communicate about Exercising of Kindness.png'
import ApplyPrinciples from '../assets/solution2/Apply Principles.png'
import CommunicateAboutApplyingPrinciples from '../assets/solution2/Communicate about Applying Principles.png'
import RegardUsageofGoodWords from '../assets/solution2/Regard Usage of Good Words.png'
import RegardUsageofGoodExpressions from '../assets/solution2/Regard Usage of Good Expressions.png'
import RegardUsageofPortableWords from '../assets/solution2/Regard Usage of Portable Words.png'
import RegardUsageofPortableExpressions from '../assets/solution2/Regard Usage of Portable Expressions.png'
import RegardusageofWordswithaSingleMeaning from '../assets/solution2/Regard usage of Words with a Single Meaning.png'
import RemoveTheUmatchedWordAndReplaceWithMatchedWord from '../assets/solution2/Remove The Umatched Word And Replace With Matched Word.png'
import ReplaceWithaWordThatisRelatedorMatchedIntheSentence from '../assets/solution2/Replace With a Word That is Related or Matched In the Sentence.png'
import RegardusageofwordsthatIdentifyActualEntity from '../assets/solution2/Regard usage of words that Identify Actual Entity.png'
import ProvidingInformation from '../assets/solution2/Providing Information.png'





function getSolutionFromProblem(problemMappedTo, type) {
    let solutionMappedTo = [];
    if (type === domainType.theory) {
        if (problemMappedTo.includes('Disregarding Application of Theory')) {
            solutionMappedTo.push(SolutionRegardApplicationofTheory);
        }
        if (problemMappedTo.includes('Misinterpretation of Theory')) {
            solutionMappedTo.push(SolutionGoodInterpretationofTheory);
        }
        if (problemMappedTo.includes('Error in Presentation of Theory or Improper Presentation of Theory')) {
            solutionMappedTo.push(SolutionProperPresentationofTheory1);
        }
        if (problemMappedTo.includes('Disregard Importance of Theory')) {
            solutionMappedTo.push(SolutionRegardImportanceofTheory);
        }
        if (problemMappedTo.includes('Disregard Relationship of Theory and Theory of Communication')) {
            solutionMappedTo.push(SolutionApplyTheoryofCommunicationRelationship);
        }
        if (problemMappedTo.includes('Disregard Portability of Theory')) {
            solutionMappedTo.push(SolutionApplyPortabilityofTheory);
        }
        if (problemMappedTo.includes('Disregard Theory and System Relationship')) {
            solutionMappedTo.push(SolutionApplyTheoryandSystemRelationship);
        }
        if (problemMappedTo.includes('Disregard Independency of Theory')) {
            solutionMappedTo.push(SolutionApplyIndependencyofTheory);
        }
        if (problemMappedTo.includes('Expandability of Philosophy')) {
            solutionMappedTo.push(SolutionExpandabilityofTheoryThroughAplication);
        }
        if (problemMappedTo.includes('Misapplication of Instruments')) {
            solutionMappedTo.push(SolutionGoodUseofInstruments);
        }
        if (problemMappedTo.includes('Misuse of Good Instruments')) {
            solutionMappedTo.push(SolutionGoodUseofInstruments);
        }
        if (problemMappedTo.includes('Disregard Instrument and System Relationship')) {
            solutionMappedTo.push(SolutionApplyInstrumentandSystemRelationship);
        }
        if (problemMappedTo.includes('Disregard System and System Relationship')) {
            solutionMappedTo.push(SolutionApplySystemandSystemRelationship);
        }
        if (problemMappedTo.includes('Disregard Function and System Relationship')) {
            solutionMappedTo.push(SolutionApplyFunctionandSystemRelationship);
        }
        if (problemMappedTo.includes('Misapplication of Method')) {
            solutionMappedTo.push(SolutionGoodUsageofMethods);
        }
        if (problemMappedTo.includes('Application of Bad Methods')) {
            solutionMappedTo.push(SolutionApplicationofGoodMethods);
        }
        if (problemMappedTo.includes('Disregard Method and System Relationship')) {
            solutionMappedTo.push(SolutionApplyMethodandSystemRelationship);
        }
    } else {
        if (problemMappedTo.includes('Perform inappropriate actions')) {
            solutionMappedTo.push(PerformAppropriateActions);
        }
        if (problemMappedTo.includes('Communicate about performing inappropriate actions')) {
            solutionMappedTo.push(CommunicateAboutperformingAppropriateActions);
        }
        if (problemMappedTo.includes('Misuse of instructions')) {
            solutionMappedTo.push(GoodUsageofInstructions);
        }
        if (problemMappedTo.includes('Communicate about misusing of instructions')) {
            solutionMappedTo.push(CommunicateaboutGoodUsageofInstructions);
        }
        if (problemMappedTo.includes('Use inappropriate instructions')) {
            solutionMappedTo.push(UseAppropriateInstructions);
        }
        if (problemMappedTo.includes('Communicate about using inappropriate instructions')) {
            solutionMappedTo.push(CommunicateaboutusingAppropriateInstructions);
        }
        if (problemMappedTo.includes('Disregard instructions')) {
            solutionMappedTo.push(FollowInstructions);
        }
        if (problemMappedTo.includes('Communicate about disregarding instructions')) {
            solutionMappedTo.push(CommunicateaboutFollowingInstructions);
        }
        if (problemMappedTo.includes('Misuse of objects')) {
            solutionMappedTo.push(GoodUseofObjects);
        }
        if (problemMappedTo.includes('Communicate about misuse of objects')) {
            solutionMappedTo.push(CommunicateaboutGoodUseofObjects);
        }
        if (problemMappedTo.includes('Use inappropriate objects')) {
            solutionMappedTo.push(UseAppropriateObjects1);
        }
        if (problemMappedTo.includes('Communicate about using inappropriate objects')) {
            solutionMappedTo.push(CommunicateaboutusingAppropriateObjects1);
        }
        if (problemMappedTo.includes('Misinformation')) {
            solutionMappedTo.push(ProperInformation);
        }
        if (problemMappedTo.includes('Communicate about misinformation')) {
            solutionMappedTo.push(CommunicateaboutProperInformation);
        }
        if (problemMappedTo.includes('Misinterpretation')) {
            solutionMappedTo.push(GoodInterpretation);
        }
        if (problemMappedTo.includes('Communicate about Misinterpretation')) {
            solutionMappedTo.push(CommunicateaboutGoodInterpretation);
        }
        if (problemMappedTo.includes('Follow others')) {
            solutionMappedTo.push(FollowPrinciples);
        }
        if (problemMappedTo.includes('Communicate about following others')) {
            solutionMappedTo.push(CommunicateAboutFollowingPrinciple);
        }
        if (problemMappedTo.includes('Miscommunication')) {
            solutionMappedTo.push(ProperCommunication);
        }
        if (problemMappedTo.includes('Communicate about miscommunication')) {
            solutionMappedTo.push(CommunicateaboutProperCommunication);
        }
        if (problemMappedTo.includes('Exercise of force')) {
            solutionMappedTo.push(ExerciseofKindness);
        }
        if (problemMappedTo.includes('Communicate about exercising of force')) {
            solutionMappedTo.push(CommunicateaboutExercisingofKindness); 
        }
        if (problemMappedTo.includes('Disregard principles')) {
            solutionMappedTo.push(ApplyPrinciples);
        }
        if (problemMappedTo.includes('Communicate about disregarding principles')) {
            solutionMappedTo.push(CommunicateAboutApplyingPrinciples);
        }
        if (problemMappedTo.includes('Use of bad words')) {
            solutionMappedTo.push(RegardUsageofGoodWords);
        }
        if (problemMappedTo.includes('Use of bad expressions')) {
            solutionMappedTo.push(RegardUsageofGoodExpressions);
        }
        if (problemMappedTo.includes('Use of non-portable words')) {
            solutionMappedTo.push(RegardUsageofPortableWords);
        }
        if (problemMappedTo.includes('Use of non-portable expressions')) {
            solutionMappedTo.push(RegardUsageofPortableExpressions);
        }
        if (problemMappedTo.includes('Use of words with multiple meanings')) {
            solutionMappedTo.push(RegardusageofWordswithaSingleMeaning);
        }
        if (problemMappedTo.includes('Use of words with multiple meanings')) {
            solutionMappedTo.push(RegardusageofWordswithaSingleMeaning);
        }
        if (problemMappedTo.includes('Word matching error to form expression')) {
            solutionMappedTo.push(RemoveTheUmatchedWordAndReplaceWithMatchedWord);
        }
        if (problemMappedTo.includes('Word does not match in sentence')) {
            solutionMappedTo.push(ReplaceWithaWordThatisRelatedorMatchedIntheSentence);
        }
        if (problemMappedTo.includes('Words do not identify actual entity')) {
            solutionMappedTo.push(RegardusageofwordsthatIdentifyActualEntity);
        }
        if (problemMappedTo.includes('Provide misinformation')) {
            solutionMappedTo.push(ProvidingInformation);
        }
    }

    return solutionMappedTo;
}



export { getSolutionFromProblem };