import React, { useEffect, useRef, useState } from 'react'
import InputInfo from '../../../widgets/input_info/input_info'
import './create_solution.css'
import CheckIcon from '@mui/icons-material/Check';
import { getSolutionMappedTo } from '../../../functions/get_solution_to';
import { settingsSlick } from '../../../global/http_enum';
import Slider from "react-slick";
import PagesTitle from '../../../widgets/pages_title/pages_title';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getSolutionFromProblem } from '../../../functions/solution';
export default function CreateSolutionWidget({ project, SetSolutionImplimantation }) {
    const [solutiionMappedTo, SetSolutionMappedTo] = useState([]);
    const theoryObject = useRef(project.problem.find(obj => obj.domain === project.event.domain));
    useEffect(() => {
        theoryObject.current = project.problem.find(obj => obj.domain === project.event.domain);
        SetSolutionMappedTo(getSolutionMappedTo(theoryObject.current.problem_mapped_to, project.event.domain))
    }, [project])
    return (
        <>
            <div className="title-holder">
                <PagesTitle title={'Solution'} />
                <div onClick={() => SetSolutionImplimantation(true)} className="button-with-title">
                    <h1 className='button-text'>Solution Implementation</h1>
                    <ArrowForwardIosIcon className='button-icon' />
                </div>
            </div>
            <div onClick={() => SetSolutionImplimantation(true)} className="backbutton">
                <h3 className='button-text'>Solution Implementation</h3>
                <ArrowForwardIosIcon className='button-icon' />
            </div>
            <div id='create-solution'>
                <InputInfo color={'1px solid rgba(36, 30, 102, 0.50)'} height={'50'} name={'Event name'} onClick={() => { }} readOnly={true} value={project.event.name} />
                <InputInfo color={'1px solid rgba(36, 30, 102, 0.50)'} height={'50'} name={'Negative Function Executed'} onClick={() => { }} readOnly={true} value={project.event.negative_function_exluded} />
                <InputInfo color={'1px solid rgba(36, 30, 102, 0.50)'} height={'50'} name={'Problem Identification'} onClick={() => { }} readOnly={true} value={project.event.problem_identifyed_from_event} />
                <h1 className='selected-problem-mapped-title'>Principle deficiency</h1>
                <div className='problem-mapped-selected'>
                    {theoryObject.current.pricipal_defeciency.map((e, index) => <div key={index} className="selected">{e} <CheckIcon className='icon' /> </div>)}
                </div>
                <h1 className='selected-problem-mapped-title'>Problem Mapped To</h1>
                <div className='problem-mapped-selected'>
                    {theoryObject.current.problem_mapped_to.map((e, index) => <div key={index} className="selected">{e} <CheckIcon className='icon' /> </div>)}
                </div>
                <h1 className='solution-mapped-title'>Solution Mapped</h1>
                <div className='problem-mapped-selected'>
                    {solutiionMappedTo.map((e, index) => <div key={index} className="selected">{e} <CheckIcon className='icon' /> </div>)}
                </div>
                <div className="slider-widget">
                    <Slider  {...settingsSlick}>
                        {getSolutionFromProblem(theoryObject.current.problem_mapped_to, project.event.domain).map((e, index) => <img alt=' ' src={e} key={index} ></img>)}
                    </Slider>
                </div>
            </div>
        </>

    )
}
