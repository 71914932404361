import React from 'react';
import { useNavigate } from 'react-router-dom';
import { cookiesGlobal } from '../../App';
import { requestState } from '../../global/http_enum';
import CircleProgress from '../circle_prgress/circle_progress';
import './response_http.css';
export default function ResponseHttp({ response }) {
    const navigate = useNavigate();
    if (requestState.initial === response) {
        return (
            <div className='response-data-failure'>
                <CircleProgress height='100px' width='100px' />
            </div>

        )
    } else if (requestState.internetConnection === response) {
        return (
            <div className='response-data-failure'>No internet connection</div>
        )
    } else if (requestState.invalidTokne === response) {
        cookiesGlobal.remove('auth');
        cookiesGlobal.remove('email');
        navigate('/authentication', { replace: true });
        return (
            <></>
        )
    } else {
        return (
            <div className='response-data-failure'>An Unexpected Error Occurred, try again</div>
        )
    }

}
