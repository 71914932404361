import React from 'react'
import PagesTitle from '../../widgets/pages_title/pages_title'
import './problem_screen.css'
import CreateProblemWidget from './create_problem/create_problem';
import AppBarWidget from '../../widgets/app_bar/app_bar';
import { useSelector } from 'react-redux';
import ProblemView from './problem_view/problem_view';
import { showAlert } from '../../functions/show_alert';
import { Navigate } from 'react-router-dom';
export default function ProblemScreen() {

    const state = useSelector(state => state.projectSlice.projectSelected);
    if (state.event === null) {
        showAlert(false, 'Create Event first');
        return <Navigate to={'/event'} />
    }
    const containsTheory = state.problem ? state.problem.some(obj => obj.domain === state.event.domain) : false;
    return (
        <>
            <AppBarWidget />
            <div id='problme-screen'>
                <PagesTitle title={'Problem'} />
                {containsTheory ?
                    <ProblemView problem={state.problem} event={state.event} /> :
                    <CreateProblemWidget projectSelected={state} />
                }
            </div>
        </>
    )
}