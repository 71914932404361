import React from 'react'
import './pages_title.css'
export default function PagesTitle({ title }) {
    return (
        <div id='screens-title'>
            <span></span>
            {title}
            <span></span>
        </div>
    )
}
