const mainUrl = 'https://api.slpsolver.net/';

const HttpUrls = {
    // auth
    createAccount: `${mainUrl}register`,
    login: `${mainUrl}login`,
    sendEmailConformation: `${mainUrl}forgetPassword`,
    reResendforgetPassword: `${mainUrl}ResendforgetPassword`,
    confrimCode: `${mainUrl}checkCodeEmail`,
    newPassword: `${mainUrl}changePassword`,
    // GET for delete and post for edite
    createProject: `${mainUrl}project2`,
    getProjects: `${mainUrl}project2`,
    editProject: `${mainUrl}project2`,
    deleteProject: `${mainUrl}project2/delete`,

    // create Event for user
    createEvent: `${mainUrl}event`,
    deleteEntity: `${mainUrl}delete-entity`,
    createProblem: `${mainUrl}Problem`,
    ChangeDomain: `${mainUrl}event-domain`,

    // get relationship for project / need project ID
    getRelationships: `${mainUrl}relations`,
    setPrinciple: `${mainUrl}principles`,
    setRelationShip: `${mainUrl}relation`,
    // get Flow API
    getFlow: `${mainUrl}flows`,
    CreateFlow: `${mainUrl}flow`,
    // verifications API
    getVerification: `${mainUrl}verifications`,
    createVerification: `${mainUrl}verification`,
    // report API
    getProjectReports: `${mainUrl}reports`,
}

const urlForFiles = (value) => {
    return  value;
}
export { HttpUrls, mainUrl, urlForFiles };