import React from 'react'
import './circle_progress.css'
export default function CircleProgress({ width, height }) {
    return (
        <div style={{ width: width, height: height }} id='circle-progress-indicator'>
            <div className="progress__circle">
                <span className="progress__percentage">50%</span>
            </div>
        </div>
    )
}
