import React from 'react'
import './event_screen.css'
import AppBarWidget from '../../widgets/app_bar/app_bar';
import { useSelector } from 'react-redux';
import CreationOfEvent from './create_event/create_event';
import EventView from './event_view/event_view';
export default function EventScreen() {
    const projectSelected = useSelector(state => state.projectSlice.projectSelected);
    return (
        <>
            <AppBarWidget />
            <div id='event-screen'>
                {projectSelected.event ? <EventView id={projectSelected.id} event={projectSelected.event} /> : <CreationOfEvent projectSelected={projectSelected} />}
            </div>
        </>

    )

}



