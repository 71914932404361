import React, { useEffect, useRef, useState } from 'react'
import PagesTitle from '../../../widgets/pages_title/pages_title';
import './solution_imp.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { getProblemImages } from '../../../functions/get_problem';
import Slider from 'react-slick';
import { domainType } from '../../../global/http_enum';
import InputInfo from '../../../widgets/input_info/input_info';
import { getSolutionFromProblem } from '../../../functions/solution';
import { getCorrectionForComunicationDomain, getSolutionFromProblemMappedTo, getSolutionMappedTo } from '../../../functions/get_solution_to';
import CheckIcon from '@mui/icons-material/Check';
export default function SolutionImp({ project, SetSolutionImplimantation }) {
    const [solutiionMappedTo, SetSolutionMappedTo] = useState([]);
    const theoryObject = useRef(project.problem.find(obj => obj.domain === project.event.domain));
    useEffect(() => {
        theoryObject.current = project.problem.find(obj => obj.domain === project.event.domain);
        SetSolutionMappedTo(getSolutionMappedTo(theoryObject.current.problem_mapped_to, project.event.domain))
    }, [project])
    const problemMappedToSliderRef = useRef();
    const solutionMappedToSliderRef = useRef();
    const correctionForCommunicationDomainSliderRef = useRef();
    const solutionHowToSliderRef = useRef();

    // Function to reset all sliders to the first slide
    const syncSliders = (index) => {
        if (document.getElementById('syncToggle').checked) {
            problemMappedToSliderRef.current.slickGoTo(index);
            solutionMappedToSliderRef.current.slickGoTo(index);
            if (project.event.domain === domainType.communication) {
                correctionForCommunicationDomainSliderRef.current.slickGoTo(index);
            }
            solutionHowToSliderRef.current.slickGoTo(index);
        }
    };
    const settingsSlick = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: function (currentSlide, nextSlide) {
            syncSliders(nextSlide);
        },
    };

    return (
        <>
            <div className="title-holder">
                <PagesTitle title={'Solution Implementation'} />
                <div onClick={() => SetSolutionImplimantation(false)} className="button-with-title">
                    <ArrowBackIosNewIcon className='button-icon' />
                    <h1 className='button-text'>Back</h1>
                </div>
            </div>
            <div onClick={() => SetSolutionImplimantation(false)} className="backbutton">
                <ArrowBackIosNewIcon className='button-icon' />
                <h3 className='button-text'>Back</h3>
            </div>
            <div id="solution-imp">
                <div className="solution-imformation">
                    <InputInfo color={'1px solid rgba(36, 30, 102, 0.50)'} height={'50'} name={'Event name'} onClick={() => { }} readOnly={true} value={project.event.name} />
                    <InputInfo color={'1px solid rgba(36, 30, 102, 0.50)'} height={'50'} name={'Negative Function Executed'} onClick={() => { }} readOnly={true} value={project.event.negative_function_exluded} />
                    <InputInfo color={'1px solid rgba(36, 30, 102, 0.50)'} height={'50'} name={'Problem Identification'} onClick={() => { }} readOnly={true} value={project.event.problem_identifyed_from_event} />
                    <h1 className='selected-problem-mapped-title'>Principle deficiency</h1>
                    <div className='problem-mapped-selected'>
                        {theoryObject.current.pricipal_defeciency.map((e, index) => <div key={index} className="selected">{e} <CheckIcon className='icon' /> </div>)}
                    </div>
                    <h1 className='selected-problem-mapped-title'>Problem Mapped To</h1>
                    <div className='problem-mapped-selected'>
                        {theoryObject.current.problem_mapped_to.map((e, index) => <div key={index} className="selected">{e} <CheckIcon className='icon' /> </div>)}
                    </div>
                    <h1 className='selected-problem-mapped-title'>Solution Mapped</h1>
                    <div className='problem-mapped-selected'>
                        {solutiionMappedTo.map((e, index) => <div key={index} className="selected">{e} <CheckIcon className='icon' /> </div>)}
                    </div>

                </div>
                <div className="solution-images">
                    <label style={{ marginBottom: "20px" }} class="custom-checkbox">
                        <input type="checkbox" id="syncToggle" name="syncToggle" />
                        <span className="checkmark"></span>
                        Sync Models
                    </label>
                    <div className="images">
                        <h1 className='title'>Problem Mapped To</h1>
                        <Slider ref={problemMappedToSliderRef} className='carasoul-box'  {...settingsSlick}>
                            {getProblemImages(theoryObject.current.problem_mapped_to, project.event.domain).map((e, index) => <img alt=' ' src={e} key={index} ></img>)}
                        </Slider>
                    </div>
                    <div className="images">
                        <h1 className='title'>Solution Mapped To</h1>
                        <Slider ref={solutionMappedToSliderRef} className='carasoul-box' {...settingsSlick}>
                            {getSolutionFromProblem(theoryObject.current.problem_mapped_to, project.event.domain).map((e, index) => <img alt=' ' src={e} key={index} ></img>)}
                        </Slider>
                    </div>
                    {project.event.domain === domainType.communication ?
                        <div className="images">
                            <h1 className='title'>Correction</h1>
                            <Slider ref={correctionForCommunicationDomainSliderRef} className='carasoul-box'  {...settingsSlick}>
                                {getCorrectionForComunicationDomain(theoryObject.current.problem_mapped_to,).map((e, index) => <img alt=' ' src={e} key={index} ></img>)}
                            </Slider>
                        </div>
                        : <></>}

                    <div className="images">
                        <h1 className='title'>Solution How To</h1>
                        <Slider ref={solutionHowToSliderRef} className='carasoul-box'  {...settingsSlick}>
                            {getSolutionFromProblemMappedTo(theoryObject.current.problem_mapped_to, project.event.domain).map((e, index) => <img alt=' ' src={e} key={index} ></img>)}
                        </Slider>
                    </div>

                </div>
            </div>
        </>
    )
}
