import React from 'react'
import InputInfo from '../../../widgets/input_info/input_info';
import CheckIcon from '@mui/icons-material/Check';
import './problem_view.css'
import Slider from 'react-slick';
import { settingsSlick } from '../../../global/http_enum';
import { getProblemImages } from '../../../functions/get_problem';
export default function ProblemView({ problem, event }) {
    const theoryObject = problem.find(obj => obj.domain === event.domain);
    return (
        <div id='problem-view'>
            <InputInfo name={'Event name'} onClick={() => { }} onValueChanged={() => { }} readOnly={true} value={event.name} />
            <InputInfo name={'Negative Function Executed'} onClick={() => { }} onValueChanged={() => { }} readOnly={true} value={event.negative_function_exluded} />
            <InputInfo name={'Problem Identified from Event'} onClick={() => { }} onValueChanged={() => { }} readOnly={false} value={event.problem_identifyed_from_event} />
            <h1 className='title'>Principle deficiency</h1>
            <div className='problem-mapped-selected'>
                {theoryObject.pricipal_defeciency.map((e, index) => <div key={index} className="selected">{e} <CheckIcon className='icon' /> </div>)}
            </div>
            <h1 className='title'>Problem Mapped To</h1>
            <div className='problem-mapped-selected'>
                {theoryObject.problem_mapped_to.map((e, index) => <div key={index} className="selected">{e} <CheckIcon className='icon' /> </div>)}
            </div>
            <Slider  {...settingsSlick}>
                {getProblemImages(theoryObject.problem_mapped_to, event.domain).map((e, index) => <img alt=' ' src={e} key={index} ></img>)}
            </Slider>
        </div>
    )
}
