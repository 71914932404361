import { cookiesGlobal } from "../App";
import { HttpUrls } from "../global/http_url";

const createEventAPI = async (name, location, desciption, negative_function_exluded, problem_identifyed_from_event, project_id, file, type) => {
    const token = cookiesGlobal.get('auth');
    try {
        let response = await fetch(`${HttpUrls.createEvent}/${project_id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'POST',
            body: JSON.stringify({
                'name': name,
                'location': location,
                'desciption': desciption,
                'negative_function_exluded': negative_function_exluded,
                'problem_identifyed_from_event': problem_identifyed_from_event,
                'project_id': project_id,
                'file': file,
                'domain': type,
            })
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }

    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}
export { createEventAPI };