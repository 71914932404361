import React from 'react'
import './grid_project.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { convertDay } from '../../../functions/date_converter';
import { useNavigate } from 'react-router-dom';
import { getSolutionMappedTo } from '../../../functions/get_solution_to'
export default function GridProject({ projectData, Selected, hundleSelectProject, SetEditProject, deleteProject }) {
  const navigate = useNavigate();
  let theoryObject = projectData.problem ? projectData.problem.find(obj => obj.domain === projectData.event.domain) : '...';
  return (
    <div onClick={() => { hundleSelectProject(projectData) }} onDoubleClick={() => navigate('/event')} className={`${Selected === projectData.id ? 'project-selected' : ''} project-gird`}>
      <div className="project-header">
        <h1 className='project-name'>{projectData.name}</h1>
        <div className='project-menu'>
          <MoreVertIcon />
          <div className="buttons-holder">
            <h1 onClick={() => SetEditProject(projectData.id)} className='buttons-text'>Rename</h1>
            <h1 onClick={() => deleteProject(projectData.id)} className='buttons-text'>Delete</h1>
          </div>
        </div>
      </div>
      <div className={`${Selected === projectData.id ? 'project-info-selected' : ''} project-info`}>
        <div className='title-text'>Event</div>
        <div className='event-name'>{projectData.event ? projectData.event.name : '...'}</div>
        <div className='title-text'>Problem</div>
        <div className='neg-name'>{projectData.event ? projectData.event.problem_identifyed_from_event : '...'}</div>
        <div className='title-text'>Negative function</div>
        <div className='neg-name'>{projectData.event ? projectData.event.negative_function_exluded : '...'}</div>
        <div className='title-text'>Solution</div>
        <div className='sol-name'>{theoryObject && theoryObject.problem_mapped_to ? getSolutionMappedTo(theoryObject.problem_mapped_to, projectData.event.domain)[0] : "..."}</div>
        <div className='time'>{convertDay(projectData.updated_at)}</div>
      </div>
    </div>
  )
}
