import DisregardingApplicationofTheory from '../assets/problem/Problem Disregard Application of Theory.png';
import ProblemErrorinInterpretationofTheory from '../assets/problem/Problem Error in Interpretation of Theory.png';
import ErrorinPresentationofTheoryorImproperPresentationofTheory from '../assets/problem/Problem Error in Presentation of Theory 1.png';
import ProblemDisregardImportanceofTheory from '../assets/problem/Problem Disregard Importance of Theory.png';
import ProblemDisregardTheoryofCommunicationRelationship from '../assets/problem/Problem Disregard Theory of Communication Relationship.png';
import ProblemDisregardPortabilityofTheory from '../assets/problem/Problem Disregard Portability of Theory.png';
import ProblemDisregardTheoryandSystemRelationship2 from '../assets/problem/Problem Disregard Theory and System Relationship 2.png';
import ProblemDisregardIndependencyofTheory from '../assets/problem/Problem Disregard Independency of Theory.png';
import ProblemRelatedtoExpandabilityofPhilosophy from '../assets/problem/Problem Related to Expandability of Philosophy.png';
import ProblemMisusedofInstruments from '../assets/problem/Problem Misused of Instruments.png';
import ProblemDisregardInstrumentandSystemRelationship from '../assets/problem/Problem Disregard Instrument and System Relationship.png';
import ProblemDisregardSystemandSystemRelationship from '../assets/problem/Problem Disregard System and System Relationship.png';
import ProblemDisregardFunctionandSystemRelationship from '../assets/problem/Problem Disregard Function and System Relationship.png';
import ProblemMisuseofMethods from '../assets/problem/Problem Misuse of Methods.png';
import ProblemApplicationofBadMethods from '../assets/problem/Problem Application of Bad Methods.png';
import ProbleMDisregardMethodandSystemRelationship from '../assets/problem/Problem Disregard Method and System Relationship.png';
import { domainType } from '../global/http_enum';

//// Comunication doamn images

import PerformAppropriateActions from '../assets/problem2/Perform Inappropriate Actions.png';
import CommunicateAboutPerformingInappropriatnActions from '../assets/problem2/Communicate about performing Inappropriate Actions.png';
import MisuseOfInstructions from '../assets/problem2/Misuse of Instructions.png';
import CommunicateaboutMisusingofInstructions from '../assets/problem2/Communicate about Misusing of Instructions.png';
import UseInappropriateInstructions from '../assets/problem2/Use Inappropriate Instructions.png';
import CommunicateaboutusingInappropriateInstructions from '../assets/problem2/Communicate about using Inappropriate Instructions.png';
import DisregardInstructions from '../assets/problem2/Disregard Instructions.png';
import CommunicateaboutDisregardingInstructions from '../assets/problem2/Communicate about Disregarding Instructions.png';
import MisuseofObjects from '../assets/problem2/Misuse of Objects.png';
import CommunicateaboutMisuseofObjects from '../assets/problem2/Communicate about Misuse of Objects.png';
import UseInappropriateObjects1 from '../assets/problem2/Use Inappropriate Objects 1.png';
import CommunicateaboutusingInappropriateObjects1 from '../assets/problem2/Communicate about using Inappropriate Objects 1.png';
import Misinformation from '../assets/problem2/Misinformation.png';
import CommunicateaboutMisinformation from '../assets/problem2/Communicate about Misinformation.png';
import Misinterpretation from '../assets/problem2/Misinterpretation.png';
import CommunicateaboutMisinterpretation from '../assets/problem2/Communicate about Misinterpretation.png';
import FollowOthers from '../assets/problem2/follow Others.png';
import CommunicateAboutFollowingOthers from '../assets/problem2/communicate about following others.png';
import Miscommunication from '../assets/problem2/Miscommunication.png';
import CommunicateAboutMiscommunication from '../assets/problem2/Communicate about Miscommunication.png';
import ExerciseofForce from '../assets/problem2/Exercise of Force.png';
import CommunicateaboutExercisingofForce from '../assets/problem2/Communicate about Exercising of Force.png';
import DisregardPrinciples from '../assets/problem2/Disregard Principles.png';
import CommunicateaboutDisregardingPrinciples from '../assets/problem2/Communicate about Disregarding Principles.png';
import UseofBadWords from '../assets/problem2/Use of Bad Words.png';
import UseofBadExpressions from '../assets/problem2/Use of Bad Expressions.png';
import UseofNonPortableWords from '../assets/problem2/Use of Non-Portable Words.png';
import UseofNonPortableExpressions from '../assets/problem2/Use of Non-Portable Expressions.png';
import UseofWordswithMultipleMeanings from '../assets/problem2/Use of Words with Multiple Meanings.png';
import WordMatchingErrorToExpression from '../assets/problem2/word matching error to from expression.png';
import WordDoesNotMatchInSentence from '../assets/problem2/word does not match in sentence.png';
import WordsdonotIdentifyActualEntity from '../assets/problem2/Words do not Identify Actual Entity.png';
import ProvidingMisinformation from '../assets/problem2/Providing Misinformation.png';


// get problem from somthing
function getProblemImages(problemMappedTo, type) {
    let solutionMappedTo = [];
    if (type === domainType.theory) {
        if (problemMappedTo.includes('Disregarding Application of Theory')) {
            solutionMappedTo.push(DisregardingApplicationofTheory);
        }
        if (problemMappedTo.includes('Misinterpretation of Theory')) {
            solutionMappedTo.push(ProblemErrorinInterpretationofTheory);
        }
        if (problemMappedTo.includes('Error in Presentation of Theory or Improper Presentation of Theory')) {
            solutionMappedTo.push(ErrorinPresentationofTheoryorImproperPresentationofTheory);
        }
        if (problemMappedTo.includes('Disregard Importance of Theory')) {
            solutionMappedTo.push(ProblemDisregardImportanceofTheory);
        }
        if (problemMappedTo.includes('Disregard Relationship of Theory and Theory of Communication')) {
            solutionMappedTo.push(ProblemDisregardTheoryofCommunicationRelationship);
        }
        if (problemMappedTo.includes('Disregard Portability of Theory')) {
            solutionMappedTo.push(ProblemDisregardPortabilityofTheory);
        }
        if (problemMappedTo.includes('Disregard Theory and System Relationship')) {
            solutionMappedTo.push(ProblemDisregardTheoryandSystemRelationship2);
        }
        if (problemMappedTo.includes('Disregard Independency of Theory')) {
            solutionMappedTo.push(ProblemDisregardIndependencyofTheory);
        }
        if (problemMappedTo.includes('Expandability of Philosophy')) {
            solutionMappedTo.push(ProblemRelatedtoExpandabilityofPhilosophy);
        }
        if (problemMappedTo.includes('Misapplication of Instruments')) {
            solutionMappedTo.push(ProblemMisusedofInstruments);
        }
        if (problemMappedTo.includes('Misuse of Good Instruments')) {
            solutionMappedTo.push(ProblemMisusedofInstruments);
        }
        if (problemMappedTo.includes('Disregard Instrument and System Relationship')) {
            solutionMappedTo.push(ProblemDisregardInstrumentandSystemRelationship);
        }
        if (problemMappedTo.includes('Disregard System and System Relationship')) {
            solutionMappedTo.push(ProblemDisregardSystemandSystemRelationship);
        }
        if (problemMappedTo.includes('Disregard Function and System Relationship')) {
            solutionMappedTo.push(ProblemDisregardFunctionandSystemRelationship);
        }
        if (problemMappedTo.includes('Misapplication of Method')) {
            solutionMappedTo.push(ProblemMisuseofMethods);
        }
        if (problemMappedTo.includes('Application of Bad Methods')) {
            solutionMappedTo.push(ProblemApplicationofBadMethods);
        }
        if (problemMappedTo.includes('Disregard Method and System Relationship')) {
            solutionMappedTo.push(ProbleMDisregardMethodandSystemRelationship);
        }
    } else {
        if (problemMappedTo.includes('Perform inappropriate actions')) {
            solutionMappedTo.push(PerformAppropriateActions);
        }
        if (problemMappedTo.includes('Communicate about performing inappropriate actions')) {
            solutionMappedTo.push(CommunicateAboutPerformingInappropriatnActions);
        }
        if (problemMappedTo.includes('Misuse of instructions')) {
            solutionMappedTo.push(MisuseOfInstructions);
        }
        if (problemMappedTo.includes('Communicate about misusing of instructions')) {
            solutionMappedTo.push(CommunicateaboutMisusingofInstructions);
        }
        if (problemMappedTo.includes('Use inappropriate instructions')) {
            solutionMappedTo.push(UseInappropriateInstructions);
        }
        if (problemMappedTo.includes('Communicate about using inappropriate instructions')) {
            solutionMappedTo.push(CommunicateaboutusingInappropriateInstructions);
        }
        if (problemMappedTo.includes('Disregard instructions')) {
            solutionMappedTo.push(DisregardInstructions);
        }
        if (problemMappedTo.includes('Communicate about disregarding instructions')) {
            solutionMappedTo.push(CommunicateaboutDisregardingInstructions);
        }
        if (problemMappedTo.includes('Misuse of objects')) {
            solutionMappedTo.push(MisuseofObjects);
        }
        if (problemMappedTo.includes('Communicate about misuse of objects')) {
            solutionMappedTo.push(CommunicateaboutMisuseofObjects);
        }
        if (problemMappedTo.includes('Use inappropriate objects')) {
            solutionMappedTo.push(UseInappropriateObjects1);
        }
        if (problemMappedTo.includes('Communicate about using inappropriate objects')) {
            solutionMappedTo.push(CommunicateaboutusingInappropriateObjects1);
        }
        if (problemMappedTo.includes('Misinformation')) {
            solutionMappedTo.push(Misinformation);
        }
        if (problemMappedTo.includes('Communicate about misinformation')) {
            solutionMappedTo.push(CommunicateaboutMisinformation);
        }
        if (problemMappedTo.includes('Misinterpretation')) {
            solutionMappedTo.push(Misinterpretation);
        }
        if (problemMappedTo.includes('Communicate about Misinterpretation')) {
            solutionMappedTo.push(CommunicateaboutMisinterpretation);
        }
        if (problemMappedTo.includes('Follow others')) {
            solutionMappedTo.push(FollowOthers);
        }
        if (problemMappedTo.includes('Communicate about following others')) {
            solutionMappedTo.push(CommunicateAboutFollowingOthers);
        }
        if (problemMappedTo.includes('Miscommunication')) {
            solutionMappedTo.push(Miscommunication);
        }
        if (problemMappedTo.includes('Communicate about miscommunication')) {
            solutionMappedTo.push(CommunicateAboutMiscommunication);
        }
        if (problemMappedTo.includes('Exercise of force')) {
            solutionMappedTo.push(ExerciseofForce);
        }
        if (problemMappedTo.includes('Communicate about exercising of force')) {
            solutionMappedTo.push(CommunicateaboutExercisingofForce);
        }
        if (problemMappedTo.includes('Disregard principles')) {
            solutionMappedTo.push(DisregardPrinciples);
        }
        if (problemMappedTo.includes('Communicate about disregarding principles')) {
            solutionMappedTo.push(CommunicateaboutDisregardingPrinciples);
        }
        if (problemMappedTo.includes('Use of bad words')) {
            solutionMappedTo.push(UseofBadWords);
        }
        if (problemMappedTo.includes('Use of bad expressions')) {
            solutionMappedTo.push(UseofBadExpressions);
        }
        if (problemMappedTo.includes('Use of non-portable words')) {
            solutionMappedTo.push(UseofNonPortableWords);
        }
        if (problemMappedTo.includes('Use of non-portable expressions')) {
            solutionMappedTo.push(UseofNonPortableExpressions);
        }
        // have two
        if (problemMappedTo.includes('Use of words with multiple meanings')) {
            solutionMappedTo.push(UseofWordswithMultipleMeanings);
        }
        if (problemMappedTo.includes('Use of words with multiple meanings')) {
            solutionMappedTo.push(UseofWordswithMultipleMeanings);
        }
        if (problemMappedTo.includes('Word matching error to form expression')) {
            solutionMappedTo.push(WordMatchingErrorToExpression);
        }
        if (problemMappedTo.includes('Word does not match in sentence')) {
            solutionMappedTo.push(WordDoesNotMatchInSentence);
        }
        if (problemMappedTo.includes('Words do not identify actual entity')) {
            solutionMappedTo.push(WordsdonotIdentifyActualEntity);
        }
        if (problemMappedTo.includes('Provide misinformation')) {
            solutionMappedTo.push(ProvidingMisinformation);
        }
    }

    return solutionMappedTo;
}
export { getProblemImages }