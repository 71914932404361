import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { cookiesGlobal } from '../../App';
import { requestStatusChanged } from '../../redux/project/projects';
import AppBarLogin from './app_bar_login/app_bar_login'
import './authentication.css'
import ForgotPassword from './forgot_password/forgot_password';
import LoginBox from './login_box/login_box';
import SignUpBox from './sign_up_box/sign_up_box';
const authenticationPage = {
    login: 'login',
    forgotpassword: 'password',
    signin: 'signin',
}
export { authenticationPage };
export default function AuthenticationPage() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(requestStatusChanged())
    }, [])
    const [page, SetPage] = useState(authenticationPage.login);
    const auth = cookiesGlobal.get('auth');
    if (auth !== undefined && auth !== '') {
        return <Navigate to={'/'} />
    }
    return (
        <div id='authentication'>
            <AppBarLogin />
            {page === authenticationPage.login ? <LoginBox SetPage={SetPage} /> : <></>}
            {page === authenticationPage.forgotpassword ? <ForgotPassword SetPage={SetPage} /> : <></>}
            {page === authenticationPage.signin ? <SignUpBox SetPage={SetPage} /> : <></>}
        </div>
    )
}
