import { border } from '@mui/system'
import React from 'react'
import './input_info.css'
export default function InputInfo({ name, onValueChanged, value, onClick, readOnly, height, color }) {
    return (
        <div id='input-info'>
            <h1 className='name'>{name}</h1>
            <textarea style={{ height: `${height}px`, border: color }} onClick={() => onClick()} readOnly={readOnly} value={value} onChange={(e) => onValueChanged(e.target.value)} className='input-class' type="text" />
        </div>


    )
}
