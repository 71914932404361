import React, { useState } from 'react'
import './solution_screen.css'
import CreateSolutionWidget from './create_solution/create_solution';
import AppBarWidget from '../../widgets/app_bar/app_bar';
import { useSelector } from 'react-redux';
import { showAlert } from '../../functions/show_alert';
import { Navigate } from 'react-router-dom';
import SolutionImp from './solution_implimentation/solution_imp';
export default function SolutionScreen() {
    const [solutionImplementaion, SetSolutionImplimantation] = useState(false);
    const state = useSelector(state => state.projectSlice.projectSelected);
    const containsTheory = state.problem ? state.problem.some(obj => obj.domain === state.event.domain) : false;
    if (!containsTheory) {
        showAlert(false, 'Create Problem first');
        return <Navigate to={'/problem'} />
    }
    if (solutionImplementaion) {
        return (
            <>
                <AppBarWidget />
                <div id='solution-screen'>
                    <SolutionImp SetSolutionImplimantation={SetSolutionImplimantation} project={state} />
                </div>
            </>

        )
    } else {
        return (
            <>
                <AppBarWidget />
                <div id='solution-screen'>
                    <CreateSolutionWidget SetSolutionImplimantation={SetSolutionImplimantation} project={state} />
                </div>
            </>

        )
    }

}

