import React, { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files';
import { createEventAPI } from '../../../api/event';
import { hundleRequestFailure } from '../../../functions/hundle_request_failure';
import { showAlert } from '../../../functions/show_alert';
import { HideProgressAlert, showProgressAlert } from '../../../functions/show_progress_page';
import InputInfo from '../../../widgets/input_info/input_info';
import PagesTitle from '../../../widgets/pages_title/pages_title';
import { GoogleMap, LoadScript, } from '@react-google-maps/api';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import './create_event.css';
import CreateButtonForAllScreen from '../../../widgets/create_button/create_button';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeProjectEvent } from '../../../redux/project/projects';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { youtubeRegex } from '../../../global/http_enum';
export default function CreationOfEvent({ projectSelected }) {
    const [createEvent, SetCreateEvent] = useState(false);
    const [eventType, SetEventType] = useState('Theory');
    const hundleEventClicked = () => {
        SetCreateEvent(true);
    }

    if (createEvent) {
        return <CreatEventScreen eventType={eventType} projectSelected={projectSelected} />
    } else {
        return <CreateEvent SetCreateEvent={hundleEventClicked} SetEventType={SetEventType} eventType={eventType} />
    }
}
const CreateEvent = ({ SetCreateEvent, SetEventType, eventType }) => {
    const [showChangeType, SetShowChangeType] = useState(false);
    const hundleButtonPressed = (e) => {
        SetEventType(e);
        SetShowChangeType(false);
    }
    return <>
        <div className="domain-holder">
            <PagesTitle title={'Event'} />
            <div onClick={() => SetShowChangeType(true)} className="domain-button">
                Switch Domain
                <ImportExportIcon className='icon' />
            </div>
        </div>
        <CreateButtonForAllScreen onClick={SetCreateEvent} haveDomain={true} buttonMessage={'Add New Event'} domain={`${eventType} Domain`} redMessage={'There is no event for this project tap on the plus sign to add an event'} />
        {showChangeType ? <div onClick={() => SetShowChangeType(false)} id='show-event-type'>
            <div className="alert-holder">
                <div onClick={() => hundleButtonPressed('Theory')} className="button">Theory Domain</div>
                <div onClick={() => hundleButtonPressed('Communication')} className="button">Communication Domain</div>
            </div>
        </div> : <></>}
    </>
}
const CreatEventScreen = ({ projectSelected, eventType }) => {
    const dispatch = useDispatch();
    const HundleFileUpload = (e) => {
        const pickedFile = e;
        if (pickedFile.size > 10000000) {
            showAlert(false, 'the video Size is More then 10 MB');
            return;
        }
        if (pickedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = btoa(reader.result);
                SetFile(base64String);
            };
            reader.readAsBinaryString(pickedFile);
        }
    }
    const hundleCreateEvent = async () => {
        if (eventName === '') {
            showAlert(false, 'Event name is Required');
            return;
        }
        if (eventDesc === '') {
            showAlert(false, 'Event Description is Required');
            return;
        }
        if (location === '') {
            showAlert(false, 'Event Location is Required');
            return;
        }
        if (negative === '') {
            showAlert(false, 'Negative Function Executed is Required');
            return;
        }
        if (problem === '') {
            showAlert(false, 'Problem Identified from Event is Required');
            return;
        }
        if (typeof file === "string") {
            const match = file.match(youtubeRegex);
            if (!match) {
                showAlert(false, "Oops! It looks like the YouTube link you've entered doesn't match the expected format. To ensure smooth processing, please use the 'Share' link directly from YouTube.  ")
                return;
            }
        }
        showProgressAlert();
        const response = await createEventAPI(
            eventName,
            location,
            eventDesc,
            negative,
            problem,
            projectSelected.id,
            file,
            eventType,
        );
        HideProgressAlert();
        if (response.statusCode === 200) {
            showAlert(true, 'Event Created Successfully !')
            dispatch(changeProjectEvent({ id: projectSelected.id, event: response.response }));
        } else {
            hundleRequestFailure(response)

        }


    }
    const [showUploader, SetShowUploader] = useState(false);
    const [showYoutubeLink, SetShowYoutubeLink] = useState(false);
    const [file, SetFile] = useState();
    const [showMap, SetShowMap] = useState(false);
    const [eventName, SetEventName] = useState('');
    const [eventDesc, SetEventDesc] = useState('');
    const [negative, SetNegative] = useState('');
    const [problem, SetProblem] = useState('');
    const [clickedLocation, setClickedLocation] = useState(null);
    const [location, SetLocation] = useState('');
    useEffect(() => {
        if (clickedLocation != null) {
            SetLocation(clickedLocation);
        }

    }, [clickedLocation])
    const hundleMapClicked = async (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        const geocodeURL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyA1fQ3PPqStI2hluImIPswrqIFYcSobIZI`;
        const response = await fetch(geocodeURL);
        const data = await response.json();
        const addressComponents = data.results[0].address_components;
        const country = addressComponents.find(component => component.types.includes('country'));
        if (country) {
            setClickedLocation(country.long_name);
            SetShowMap(false);
        } else {
            showAlert(false, 'get Closer To the Country then Click it')
        }
    };
    const setYoutube = () => {
        SetShowYoutubeLink(state => !state);
        SetShowUploader(false);
        if (showUploader) {
            SetFile();
        }
    }
    const setUploader = () => {
        SetShowYoutubeLink(false);
        SetShowUploader(state => !state);
        if (showYoutubeLink) {
            SetFile();
        }
    }
    return <>
        <PagesTitle title={'Add New Event'} />
        <div className="event-details">
            <div className="upload-files-buttons">
                <div onClick={() => setUploader()} className="upload-file-button">Upload Files</div>
                <div style={{ marginLeft: "10px" }} onClick={() => setYoutube()} className="upload-file-button"><YouTubeIcon style={{ marginRight: "10px" }} /> Youtube Link</div>
            </div>
            <div className="uploader">
                {showUploader ? <FileUploader multiple={false} handleChange={(e) => HundleFileUpload(e)} /> : <></>}
                {showYoutubeLink ? <div style={{width:"100%"}}><InputInfo name={'Youtube Link'} onClick={() => { }} onValueChanged={SetFile} readOnly={false} value={file} /> </div> : <></>}
            </div>
            <InputInfo name={'Event name'} onClick={() => { }} onValueChanged={SetEventName} readOnly={false} value={eventName} />
            <InputInfo name={'Event location'} onClick={() => { }} onValueChanged={SetLocation} readOnly={false} value={location} />
            <InputInfo name={'Negative Function Executed'} onClick={() => { }} onValueChanged={SetNegative} readOnly={false} value={negative} />
            <InputInfo name={'Problem Identified from Event'} onClick={() => { }} onValueChanged={SetProblem} readOnly={false} value={problem} />
            <div className="descritpion-quill">
                <h1 className='quill-title'>Event Description</h1>
                <ReactQuill className='qill-editor' theme="snow" value={eventDesc} onChange={SetEventDesc} />
            </div>

        </div>
        <div className="save-event-holder">
            <div onClick={() => SetShowMap(true)} className="button">Show Event Map</div>
            <div onClick={() => hundleCreateEvent()} className="button">save</div>
        </div>
        {showMap ? <MapWidget SetShowMap={SetShowMap} handleClick={hundleMapClicked} /> : <></>}
    </>
}
const containerStyle = {
    width: '100%',
    height: '100%'
};
const defaultCenter = {
    lat: 20.5937,
    lng: 78.9629
};
function MapWidget({ handleClick, SetShowMap }) {
    return <>
        <div onClick={() => SetShowMap(false)} className="background"></div>
        <div id="map">
            <LoadScript googleMapsApiKey="AIzaSyA1fQ3PPqStI2hluImIPswrqIFYcSobIZI">
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    zoom={1}
                    center={defaultCenter}
                    onClick={handleClick}
                />
            </LoadScript>
        </div>
    </>

}
