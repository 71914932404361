import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { cookiesGlobal } from '../App';
function AuthGuarder() {
    const auth = cookiesGlobal.get('auth')
    if (auth === undefined || auth === '') {
        return <Navigate to={'/authentication'} />
    } else {
        return <Outlet />
    }

}

export default AuthGuarder;