const requestState = {
    initial: 'initial',
    serverError: 'serverError',
    internetConnection: 'internetConnection',
    haveData: 'haveData',
    invalidTokne: 'invalidToken',
}
const StepScreenEnum = {
    doesNotHave: 'doesNotHave',
    Create: 'Create',
    HaveCreated: 'HaveCreated',
}
const domainType = {
    theory: 'Theory',
    communication: 'Communication',
}
const settingsSlick = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};
function shortenText(text, maxLength) {
    if (text.length > maxLength) {
        return text.substring(0, maxLength - 3) + "...";
    } else {
        return text;
    }
}
const youtubeRegex = /https:\/\/youtu\.be\/([a-zA-Z0-9_-]{11})(\?.*)?$/;
const extractVideoId = (link) => {
    const regex = /youtu\.be\/(.+)$/;
    const match = link.match(regex);
    const id = match ? match[1] : null;
    return id;

};
export { requestState, StepScreenEnum, domainType, settingsSlick, shortenText, youtubeRegex, extractVideoId };