import React, { useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import HttpsIcon from '@mui/icons-material/Https';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import LoginInputWidget from '../../../widgets/input/login_input';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import './sign_up_box.css'
import { Link, useNavigate } from 'react-router-dom';
import CircleProgress from '../../../widgets/circle_prgress/circle_progress';
import { createUser } from '../../../api/auth';
import { showAlert } from '../../../functions/show_alert';
import { authenticationPage } from '../authentication';
export default function SignUpBox({ SetPage }) {
    const navigate = useNavigate();
    const [email, SetEmail] = useState('');
    const [phone, SetPhone] = useState('');
    const [password, SetPassword] = useState('');
    const [rePassword, SetRePassword] = useState('');
    const [sendingData, SetSendingData] = useState(false);
    const signInFunction = async () => {
        const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(email)) {
            showAlert(false, 'Enter Valid Email');
            return;
        }
        if (password !== rePassword) {
            showAlert(false, 'Passwords and re-password do not match ');
            return;
          }
          if (password.length < 6) {
            showAlert(false, 'password is not strong enough');
            return;
          }
        SetSendingData(true);
        const response = await createUser(email, phone, password, rePassword);
        if (response.statusCode === 200) {
            showAlert(true, 'Welcome To Problem Mapping');
            navigate('/');
        } else {
            showAlert(false, response.response);
            SetSendingData(false);
        }
    }
    return (
        <div id='sign-up-box'>
            <h1 className='login-box-title'>Create New Account </h1>
            <form className='form-login'>
                <LoginInputWidget width={'297px'} height={'50px'} value={email} setValue={SetEmail} type={'email'} widget={<PersonIcon />} placeholder={'Enter a valid email address'} />
                <LoginInputWidget width={'297px'} height={'50px'} value={phone} setValue={SetPhone} type={'number'} widget={<LocalPhoneIcon />} placeholder={'Enter a valid Phone number'} />
                <LoginInputWidget width={'297px'} height={'50px'} value={password} setValue={SetPassword} type={'password'} widget={<HttpsIcon />} placeholder={'Enter a valid password'} />
                <LoginInputWidget width={'297px'} height={'50px'} value={rePassword} setValue={SetRePassword} type={'password'} widget={<HttpsIcon />} placeholder={'re-enter your password'} />
            </form>
            <div onClick={() => SetPage(authenticationPage.forgotpassword)} className='forget-password-link'>Forget password</div>
            {
                sendingData ? <CircleProgress width={'50px'} height={'50px'} /> : <div onClick={() => signInFunction()} className='login-button'><h1>Create Account</h1><ArrowCircleRightIcon className='icon' /></div>
            }
            <div className="create-account-link">have account ? <Link onClick={() => SetPage(authenticationPage.login)} className='link' >Login </Link></div>
        </div>
    )
}


