import React, { useEffect, useRef, useState } from 'react'
import './report_screen.css';
import PagesTitle from '../../widgets/pages_title/pages_title'
import { domainType } from '../../global/http_enum';
import AppBarWidget from '../../widgets/app_bar/app_bar';
import { useDispatch, useSelector } from 'react-redux';
import { getSolutionHowToPoints, getSolutionMappedTo } from '../../functions/get_solution_to';
import { convertDay } from '../../functions/date_converter';
import { HideProgressAlert, showProgressAlert } from '../../functions/show_progress_page';
import { changeProjectDomainAPI } from '../../api/project';
import { editProjectDomainByID } from '../../redux/project/projects';
import { hundleRequestFailure } from '../../functions/hundle_request_failure';
export default function ReportScreen() {
    const state = useSelector(state => state.projectSlice.projectSelected);
    const dispatch = useDispatch();
    const [domain, SetDomainType] = useState(state.event.domain);
    const theoryObject = useRef(state.problem ? state.problem.find(obj => obj.domain === state.event.domain) : null);
    useEffect(() => {
        theoryObject.current = state.problem ? state.problem.find(obj => obj.domain === state.event.domain) : null;
        SetDomainType(state.event.domain)
    }, [state])
    const changeDomain = async (domains) => {
        if (domain === domains) {
            return;
        }
        showProgressAlert();
        const response = await changeProjectDomainAPI(state.event.id, domains);
        HideProgressAlert();
        if (response.statusCode < 300) {
            dispatch(editProjectDomainByID({ id: state.event.id, newDomain: domains }));
            theoryObject.current = state.problem ? state.problem.find(obj => obj.domain === domains) : null;
            SetDomainType(domains);
        } else {
            hundleRequestFailure(response);
        }
    }
    return (
        <>
            <AppBarWidget />
            <div id='report-screen'>
                <div className="title-mobile">
                    <PagesTitle title={domain === domainType.theory ? 'Theory domain' : 'Communication domain'} />
                </div>
                <div className="title-holder">
                    <PagesTitle title={'Reports'} />
                    <div className="domain-type">
                        <div onClick={() => changeDomain(domainType.theory)} className={`${domain === domainType.theory ? 'active' : 'not-active'}`}>Theory Domain</div>
                        <div onClick={() => changeDomain(domainType.communication)} className={`${domain === domainType.communication ? 'active' : 'not-active'}`}>Communication Domain</div>
                    </div>
                </div>
                <div className="info-holder">
                    <div className="about-event">  About Event </div>
                    <div className="line"></div>
                    <div className="info-section">
                        <div className='title'>Event Title</div>
                        <div className='info'>:  {state.event.name}  </div>
                    </div>
                    <div className="info-section">
                        <div className='title'>Event Location: <span className='info'>{state.event.location}</span></div>
                    </div>
                    <div className="info-section">
                        <div className='title'>Event Date: <span className='info'>{convertDay(state.updated_at)}</span></div>
                    </div>
                    <div style={{ marginBottom: "10px" }} className="title-alone">Event Description: </div>
                    <p dangerouslySetInnerHTML={{ __html: state.event.desciption }} className='paragraph'></p>
                    <div className="line black"></div>
                    <div className="info-section">
                        <div className='title'>Negative Function Executed :<span className='info reds'> {state.event.negative_function_exluded}</span></div>
                    </div>
                    <div className="about-problem">About Problem </div>
                    <div className="line"></div>
                    <div className="title-alone">Problem Mapped To:</div>
                    {theoryObject.current ?
                        <ul className='paragraph list-margin'>
                            {theoryObject.current.problem_mapped_to.map((e, index) => <li> <p className='reds'>{e}</p></li>)}
                        </ul>
                        : <></>
                    }
                    <div className="space"></div>
                    <div className="title-alone">Principle Deficiency : {theoryObject.current ? <span className='info blue'>{theoryObject.current.pricipal_defeciency.map((e, index) => <> {e} {theoryObject.current.pricipal_defeciency.length - 1 === index ? '' : ','}</>)}</span> : <></>}</div>
                    <div className="about-solution">About Solution</div>
                    <div className="line"></div>
                    <div className="title-alone">Principle Needed : {theoryObject.current ? <span className='info blue'>{theoryObject.current.pricipal_defeciency.map((e, index) => <> {e} {theoryObject.current.pricipal_defeciency.length - 1 === index ? '' : ','}</>)}</span> : <></>}</div>
                    <div className="space"></div>
                    <div className="title-alone">Solution Mapped To:</div>
                    {theoryObject.current ?
                        <ul className='paragraph list-margin'>
                            {getSolutionMappedTo(theoryObject.current.problem_mapped_to, domain).map((e, index) => <li> <p className='green'>{e}</p></li>)}
                        </ul>
                        : <></>
                    }
                    <div className="space"></div>
                    <div className="about-solution">About Solution Implementation</div>
                    <div className="line"></div>
                    <div className="title-alone">Solution How To:</div>
                    {theoryObject.current ?
                        <ul className='paragraph list-margin'>
                            {getSolutionHowToPoints(theoryObject.current.problem_mapped_to, domain).map((e, index) => {
                                if (e === ' ') {
                                    return <br></br>
                                } else {
                                    return <li> <p className='green'>{e}</p></li>
                                }
                            })}
                        </ul>
                        : <></>
                    }
                </div>

            </div>
        </>

    )
}
