import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { showAlert } from '../functions/show_alert';
function LogicGuard() {
    const state = useSelector(state => state.projectSlice.projectSelected)
    if (!state) {
        showAlert(false, 'pick project by clicking on it ');
        return <Navigate to={'/'} replace={true} />
    } else {
        return <Outlet />
    }

}

export default LogicGuard;