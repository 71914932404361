import RegardingApplicationofTheory from '../assets/how to/Solution Implemenation Application of Theory How To.png';
import ProperInterpretationofTheory from '../assets/how to/Solution Implementation Interpretation of Theory How To 1.png'
import ProperPresentationofTheor from '../assets/how to/Solution Impelementation Presentation of Theory How To.png'
import RegardImportanceofTheory from '../assets/how to/Solution Implementation Importance of Theory How To.png'
import UnderstandingRelationshipofTheoryandTheoryofCommunication from '../assets/how to/Solution Implementation Relationship of Theory and Theory of Communication How To.png'
import ApplyPortabilityofTheory from '../assets/how to/Solution Implementation Portability of Theory How To.png'
import ApplyTheoryandSystemRelationship from '../assets/how to/Solution Impelementation Theory and System Relationship How To.png'
import ApplyIndependencyofTheory from '../assets/how to/Solution Implemenation Independency of Theory How To.png'
import DropThePhilosophyandApplytheTheory from '../assets/how to/Solution Implemenation Philosophy and The Theory How To.png'
import GoodUsageofGoodInstruments from '../assets/how to/Solution Implementation Use of Good Instruments How To.png'
import GoodApplicationofInstruments from '../assets/how to/Solution Implementation Application of Instruments How To.png'
import ApplyInstrumentandSystemRelationship from '../assets/how to/Solution Implementation Instrument and System Relationship How To.png'
import ApplySystemandSystemRelationship from '../assets/how to/Solution Implemenationo System and System Relationship How To.png'
import ApplyFunctionandSystemRelationship from '../assets/how to/Solution Implementation Function and System Relationship How To.png'
import GoodApplicationofMethod from '../assets/how to/Solution Implementation Application of Method How To.png'
import ApplicationofGoodMethods from '../assets/how to/Solution Implementation Application of Good and Bad Methods How To.png'
import ApplyMethodandSystemRelationship from '../assets/how to/Solution Implementation Method and System Relationship How To.png'
import { domainType } from '../global/http_enum'

import SolutionHowToPerformAppropriateAction from '../assets/how to 2/Solution How To Perform Appropriate Action.png'
import SolutionHowToGoodusageofInstructions from '../assets/how to 2/Solution How To Good usage of Instructions.png'
import SolutionHowToUseAppropriateInstructions from '../assets/how to 2/Solution How To Use Appropriate Instructions.png'
import SolutionHowToFollowInstructions from '../assets/how to 2/Solution How To Follow Instructions.png'
import SolutionHowToGooduseofObjects from '../assets/how to 2/Solution How To Good use of Objects.png'
import SolutionHowToUseAppropriateObjects from '../assets/how to 2/Solution How To Use Appropriate Objects.png'
import SolutionHowToProperInformation from '../assets/how to 2/Solution How To Proper Information.png'
import SolutionHowToGoodinterpretation from '../assets/how to 2/Solution How To Good interpretation.png'
import SolutionHowToFollowprinciples from '../assets/how to 2/Solution How To Follow principles.png'
import SolutionHowToProperCommunication from '../assets/how to 2/Solution How To Proper Communication.png'
import SolutionHowToExercisekindness from '../assets/how to 2/Solution How To Exercise kindness.png'
import SolutionHowToApplyprinciples from '../assets/how to 2/Solution How To Apply principles.png'
import SolutionHowToRegardusageofgoodwords from '../assets/how to 2/Solution How To Regard usage of good words.png'
import SolutionHowToRegardusageofgoodexpressions from '../assets/how to 2/Solution How To Regard usage of good expressions.png'
import SolutionHowToRegardusageofportablewords from '../assets/how to 2/Solution How To Regard usage of portable words.png'
import SolutionHowToRegardusageofportableexpressions from '../assets/how to 2/Solution How To Regard usage of portable expressions.png'
import SolutionHowToRegardusageofwordswithasinglemeaning from '../assets/how to 2/Solution How To Regard usage of words with a single meaning.png'
import SolutionHowToRegardusageoftherightmeaningoftheword from '../assets/how to 2/Solution How To Regard usage of the right meaning of the word.png'
import SolutionHowToRemovetheunmatchedwordandreplacewithamatchedword from '../assets/how to 2/Solution How To Remove the unmatched word and replace with a matched word.png'
import SolutionHowToReplacewithawordthatisrelatedormatchedinthesentence from '../assets/how to 2/Solution How To Replace with a word that is related or matched in the sentence.png'
import SolutionHowToRegardusageofwordsthatidentifyactualentity from '../assets/how to 2/Solution How To Regard usage of words that identify actual entity.png'
import SolutionHowToProvideInformation from '../assets/how to 2/Solution How To Provide information.png'
/// correction import 
import CorrectionPerformInappropriateAction from '../assets/correction/Correction Perform Inappropriate Action.png'
import CorrectionCommunicateaboutPerformInappropriateAction from '../assets/correction/Correction Communicate about Perform Inappropriate Action.png'
import CorrectionMisuseofInstructions from '../assets/correction/Correction Misuse of Instructions.png'
import CorrectionCommunicateaboutMisuseofInstructions from '../assets/correction/Correction Communicate about Misuse of Instructions.png'
import CorrectionUseInappropriateInstructions from '../assets/correction/Correction Use Inappropriate Instructions.png'
import CorrectionCommunicateaboutUsingInappropriateInstructions from '../assets/correction/Correction Communicate about Using Inappropriate Instructions.png'
import CorrectiondisregardInstructions from '../assets/correction/Correction disregard Instructions.png'
import CorrectionCommunicateaboutDisregardingInstructions from '../assets/correction/Correction Communicate about Disregarding Instructions.png'
import CorrectionMisuseofObjects from '../assets/correction/Correction Misuse of Objects.png'
import CorrectionCommunicateaboutMisuseofObjects from '../assets/correction/Correction Communicate about Misuse of Objects.png'
import CorrectionUseInappropriateObjects from '../assets/correction/Correction Use Inappropriate Objects.png'
import CorrectionCommunicateaboutUsingInappropriateObjects from '../assets/correction/Correction Communicate about Using Inappropriate Objects.png'
import CorrectionMisinformation from '../assets/correction/Correction Misinformation.png'
import CorrectionCommunicateaboutMisinformation from '../assets/correction/Correction Communicate about Misinformation.png'
import CorrectionMisinterpretation from '../assets/correction/Correction Misinterpretation.png'
import CorrectionCommunicateaboutMisinterpretation from '../assets/correction/Correction Communicate about Misinterpretation.png'
import CorrectionFollowOthers from '../assets/correction/Correction Follow Others.png'
import CorrectionCommunicateaboutFollowingOthers from '../assets/correction/Correction Communicate about Following Others.png'
import CorrectionMiscommunication from '../assets/correction/Correction Miscommunication.png'
import CorrectionCommunicateaboutMiscommunication from '../assets/correction/Correction Communicate about Miscommunication.png'
import CorrectionExerciseofForce from '../assets/correction/Correction Exercise of Force.png'
import CorrectionCommunicateaboutExercisingofForce from '../assets/correction/Correction Communicate about Exercising of Force.png'
import CorrectionDisregardPrinciples from '../assets/correction/Correction Disregard Principles.png'
import CorrectionCommunicateaboutDisregardingPrinciples from '../assets/correction/Correction Communicate about Disregarding Principles.png'
import CorrectionUseofBadWords from '../assets/correction/Correction Use of Bad Words.png'
import CorrectionUseofBadExpressions from '../assets/correction/Correction Use of Bad Expressions.png'
import CorrectionUseofNonportableWords from '../assets/correction/Correction Use of Non-portable Words.png'
import CorrectionUseofNonportableExpressions from '../assets/correction/Correction Use of Non-portable Expressions.png'
import CorrectionUseofWordswithMultipleMeanings from '../assets/correction/Correction Use of Words with Multiple Meanings.png'
import CorrectionWordMatchingErrortoFormExpression from '../assets/correction/Correction Word Matching Error to Form Expression.png'
import CorrectionWorddoesnotMatchinSentence from '../assets/correction/Correction Word does not Match in Sentence.png'
import CorrectionWordsdonotidentifyactualentity from '../assets/correction/Correction Words do not identify actual entity.png'
import CorrectionProvideMisinformation from '../assets/correction/Correction Provide Misinformation.png'


// get solution how to String from problem
function getSolutionMappedTo(problemMappedTo, type) {
    let solutionMappedTo = [];
    if (type === domainType.theory) {
        if (problemMappedTo.includes('Disregarding Application of Theory')) {
            solutionMappedTo.push('Regarding Application of Theory');
        }
        if (problemMappedTo.includes('Misinterpretation of Theory')) {
            solutionMappedTo.push('Proper Interpretation of Theory');
        }
        if (problemMappedTo.includes('Error in Presentation of Theory or Improper Presentation of Theory')) {
            solutionMappedTo.push('Proper Presentation of Theory');
        }
        if (problemMappedTo.includes('Disregard Importance of Theory')) {
            solutionMappedTo.push('Regard Importance of Theory');
        }
        if (problemMappedTo.includes('Disregard Relationship of Theory and Theory of Communication')) {
            solutionMappedTo.push('Understanding Relationship of Theory and Theory of Communication');
        }
        if (problemMappedTo.includes('Disregard Portability of Theory')) {
            solutionMappedTo.push('Apply Portability of Theory');
        }
        if (problemMappedTo.includes('Disregard Theory and System Relationship')) {
            solutionMappedTo.push('Apply Theory and System Relationship');
        }
        if (problemMappedTo.includes('Disregard Independency of Theory')) {
            solutionMappedTo.push('Apply Independency of Theory');
        }
        if (problemMappedTo.includes('Expandability of Philosophy')) {
            solutionMappedTo.push('Drop The Philosophy and Apply the Theory');
        }
        if (problemMappedTo.includes('Misapplication of Instruments')) {
            solutionMappedTo.push('Good Application of Instruments');
        }
        if (problemMappedTo.includes('Misuse of Good Instruments')) {
            solutionMappedTo.push('Good Usage of Good Instruments');
        }
        if (problemMappedTo.includes('Disregard Instrument and System Relationship')) {
            solutionMappedTo.push('Apply Instrument and System Relationship');
        }
        if (problemMappedTo.includes('Disregard System and System Relationship')) {
            solutionMappedTo.push('Apply System and System Relationship');
        }
        if (problemMappedTo.includes('Disregard Function and System Relationship')) {
            solutionMappedTo.push('Apply Function and System Relationship');
        }
        if (problemMappedTo.includes('Misapplication of Method')) {
            solutionMappedTo.push('Good Application of Method');
        }
        if (problemMappedTo.includes('Application of Bad Methods')) {
            solutionMappedTo.push('Application of Good Methods');
        }
        if (problemMappedTo.includes('Disregard Method and System Relationship')) {
            solutionMappedTo.push('Apply Method and System Relationship');
        }
    } else {
        if (problemMappedTo.includes('Perform inappropriate actions')) {
            solutionMappedTo.push('Perform appropriate actions');
        }
        if (problemMappedTo.includes('Communicate about performing inappropriate actions')) {
            solutionMappedTo.push('Communicate about perform appropriate actions');
        }
        if (problemMappedTo.includes('Misuse of instructions')) {
            solutionMappedTo.push('Good usage of instructions');
        }
        if (problemMappedTo.includes('Communicate about misusing of instructions')) {
            solutionMappedTo.push('Communicate about good usage of instructions');
        }
        if (problemMappedTo.includes('Use inappropriate instructions')) {
            solutionMappedTo.push('Use appropriate instructions');
        }
        if (problemMappedTo.includes('Communicate about using inappropriate instructions')) {
            solutionMappedTo.push('Communicate about using appropriate instructions');
        }
        if (problemMappedTo.includes('Disregard instructions')) {
            solutionMappedTo.push('Follow instructions');
        }
        if (problemMappedTo.includes('Communicate about disregarding instructions')) {
            solutionMappedTo.push('Communicate about following instructions');
        }
        if (problemMappedTo.includes('Misuse of objects')) {
            solutionMappedTo.push('Good use of objects');
        }
        if (problemMappedTo.includes('Communicate about misuse of objects')) {
            solutionMappedTo.push('Communicate about good use of objects');
        }
        if (problemMappedTo.includes('Use inappropriate objects')) {
            solutionMappedTo.push('Use appropriate objects');
        }
        if (problemMappedTo.includes('Communicate about using inappropriate objects')) {
            solutionMappedTo.push('Communicate about using appropriate objects');
        }
        if (problemMappedTo.includes('Misinformation')) {
            solutionMappedTo.push('Proper information');
        }
        if (problemMappedTo.includes('Communicate about misinformation')) {
            solutionMappedTo.push('Communicate about proper information');
        }
        if (problemMappedTo.includes('Misinterpretation')) {
            solutionMappedTo.push('Good interpretation');
        }
        if (problemMappedTo.includes('Communicate about Misinterpretation')) {
            solutionMappedTo.push('Communicate about good interpretation');
        }
        if (problemMappedTo.includes('Follow others')) {
            solutionMappedTo.push('Follow principles');
        }
        if (problemMappedTo.includes('Communicate about following others')) {
            solutionMappedTo.push('Communicate about following principles');
        }
        if (problemMappedTo.includes('Miscommunication')) {
            solutionMappedTo.push('Proper Communication');
        }
        if (problemMappedTo.includes('Communicate about miscommunication')) {
            solutionMappedTo.push('Communicate about proper Communication');
        }
        if (problemMappedTo.includes('Exercise of force')) {
            solutionMappedTo.push('Exercise kindness');
        }
        if (problemMappedTo.includes('Communicate about exercising of force')) {
            solutionMappedTo.push('Communicate about exercising of kindness');
        }
        if (problemMappedTo.includes('Disregard principles')) {
            solutionMappedTo.push('Apply principles');
        }
        if (problemMappedTo.includes('Communicate about disregarding principles')) {
            solutionMappedTo.push('Communicate about applying principles');
        }
        if (problemMappedTo.includes('Use of bad words')) {
            solutionMappedTo.push('Regard usage of good words');
        }
        if (problemMappedTo.includes('Use of bad expressions')) {
            solutionMappedTo.push('Regard usage of good expressions');
        }
        if (problemMappedTo.includes('Use of non-portable words')) {
            solutionMappedTo.push('Regard usage of portable words');
        }
        if (problemMappedTo.includes('Use of non-portable expressions')) {
            solutionMappedTo.push('Regard usage of portable expressions');
        }
        if (problemMappedTo.includes('Use of words with multiple meanings')) {
            solutionMappedTo.push('Regard usage of words with a single meaning');
        }
        if (problemMappedTo.includes('Use of words with multiple meanings')) {
            solutionMappedTo.push('Regard usage of the right meaning of the word, rather than using the word');
        }
        if (problemMappedTo.includes('Word matching error to form expression')) {
            solutionMappedTo.push('Remove the unmatched word and replace with a matched word');
        }
        if (problemMappedTo.includes('Word does not match in sentence')) {
            solutionMappedTo.push('Replace with a word that is related or matched in the sentence');
        }
        if (problemMappedTo.includes('Words do not identify actual entity')) {
            solutionMappedTo.push('Regard usage of words that identify actual entity');
        }
        if (problemMappedTo.includes('Provide misinformation')) {
            solutionMappedTo.push('Provide information');
        }
    }
    return solutionMappedTo;
}
// get SOLUTtion how to image from problem
function getSolutionFromProblemMappedTo(problemMappedTo, type) {
    let solutionMappedTo = [];
    if (type === domainType.theory) {
        if (problemMappedTo.includes('Disregarding Application of Theory')) {
            solutionMappedTo.push(RegardingApplicationofTheory);
        }
        if (problemMappedTo.includes('Misinterpretation of Theory')) {
            solutionMappedTo.push(ProperInterpretationofTheory);
        }
        if (problemMappedTo.includes('Error in Presentation of Theory or Improper Presentation of Theory')) {
            solutionMappedTo.push(ProperPresentationofTheor);
        }
        if (problemMappedTo.includes('Disregard Importance of Theory')) {
            solutionMappedTo.push(RegardImportanceofTheory);
        }
        if (problemMappedTo.includes('Disregard Relationship of Theory and Theory of Communication')) {
            solutionMappedTo.push(UnderstandingRelationshipofTheoryandTheoryofCommunication);
        }
        if (problemMappedTo.includes('Disregard Portability of Theory')) {
            solutionMappedTo.push(ApplyPortabilityofTheory);
        }
        if (problemMappedTo.includes('Disregard Theory and System Relationship')) {
            solutionMappedTo.push(ApplyTheoryandSystemRelationship);
        }
        if (problemMappedTo.includes('Disregard Independency of Theory')) {
            solutionMappedTo.push(ApplyIndependencyofTheory);
        }
        if (problemMappedTo.includes('Expandability of Philosophy')) {
            solutionMappedTo.push(DropThePhilosophyandApplytheTheory);
        }
        if (problemMappedTo.includes('Misapplication of Instruments')) {
            solutionMappedTo.push(GoodApplicationofInstruments);
        }
        if (problemMappedTo.includes('Misuse of Good Instruments')) {
            solutionMappedTo.push(GoodUsageofGoodInstruments);
        }
        if (problemMappedTo.includes('Disregard Instrument and System Relationship')) {
            solutionMappedTo.push(ApplyInstrumentandSystemRelationship);
        }
        if (problemMappedTo.includes('Disregard System and System Relationship')) {
            solutionMappedTo.push(ApplySystemandSystemRelationship);
        }
        if (problemMappedTo.includes('Disregard Function and System Relationship')) {
            solutionMappedTo.push(ApplyFunctionandSystemRelationship);
        }
        if (problemMappedTo.includes('Misapplication of Method')) {
            solutionMappedTo.push(GoodApplicationofMethod);
        }
        if (problemMappedTo.includes('Application of Bad Methods')) {
            solutionMappedTo.push(ApplicationofGoodMethods);
        }
        if (problemMappedTo.includes('Disregard Method and System Relationship')) {
            solutionMappedTo.push(ApplyMethodandSystemRelationship);
        }
    } else {
        if (problemMappedTo.includes('Perform inappropriate actions')) {
            solutionMappedTo.push(SolutionHowToPerformAppropriateAction);
        }
        if (problemMappedTo.includes('Communicate about performing inappropriate actions')) {
            solutionMappedTo.push(SolutionHowToPerformAppropriateAction);
        }
        if (problemMappedTo.includes('Misuse of instructions')) {
            solutionMappedTo.push(SolutionHowToGoodusageofInstructions);
        }
        if (problemMappedTo.includes('Communicate about misusing of instructions')) {
            solutionMappedTo.push(SolutionHowToGoodusageofInstructions);
        }
        if (problemMappedTo.includes('Use inappropriate instructions')) {
            solutionMappedTo.push(SolutionHowToUseAppropriateInstructions);
        }
        if (problemMappedTo.includes('Communicate about using inappropriate instructions')) {
            solutionMappedTo.push(SolutionHowToUseAppropriateInstructions);
        }
        if (problemMappedTo.includes('Disregard instructions')) {
            solutionMappedTo.push(SolutionHowToFollowInstructions);
        }
        if (problemMappedTo.includes('Communicate about disregarding instructions')) {
            solutionMappedTo.push(SolutionHowToFollowInstructions);
        }
        if (problemMappedTo.includes('Misuse of objects')) {
            solutionMappedTo.push(SolutionHowToGooduseofObjects);
        }
        if (problemMappedTo.includes('Communicate about misuse of objects')) {
            solutionMappedTo.push(SolutionHowToGooduseofObjects);
        }
        if (problemMappedTo.includes('Use inappropriate objects')) {
            solutionMappedTo.push(SolutionHowToPerformAppropriateAction);
        }
        if (problemMappedTo.includes('Communicate about using inappropriate objects')) {
            solutionMappedTo.push(SolutionHowToUseAppropriateObjects);
        }
        if (problemMappedTo.includes('Misinformation')) {
            solutionMappedTo.push(SolutionHowToProperInformation);
        }
        if (problemMappedTo.includes('Communicate about misinformation')) {
            solutionMappedTo.push(SolutionHowToProperInformation);
        }
        if (problemMappedTo.includes('Misinterpretation')) {
            solutionMappedTo.push(SolutionHowToGoodinterpretation);
        }
        if (problemMappedTo.includes('Communicate about Misinterpretation')) {
            solutionMappedTo.push(SolutionHowToGoodinterpretation);
        }
        if (problemMappedTo.includes('Follow others')) {
            solutionMappedTo.push(SolutionHowToFollowprinciples);
        }
        if (problemMappedTo.includes('Communicate about following others')) {
            solutionMappedTo.push(SolutionHowToFollowprinciples);
        }
        if (problemMappedTo.includes('Miscommunication')) {
            solutionMappedTo.push(SolutionHowToProperCommunication);
        }
        if (problemMappedTo.includes('Communicate about miscommunication')) {
            solutionMappedTo.push(SolutionHowToProperCommunication);
        }
        if (problemMappedTo.includes('Exercise of force')) {
            solutionMappedTo.push(SolutionHowToExercisekindness);
        }
        if (problemMappedTo.includes('Communicate about exercising of force')) {
            solutionMappedTo.push(SolutionHowToExercisekindness);
        }
        if (problemMappedTo.includes('Disregard principles')) {
            solutionMappedTo.push(SolutionHowToApplyprinciples);
        }
        if (problemMappedTo.includes('Communicate about disregarding principles')) {
            solutionMappedTo.push(SolutionHowToApplyprinciples);
        }
        if (problemMappedTo.includes('Use of bad words')) {
            solutionMappedTo.push(SolutionHowToRegardusageofgoodwords);
        }
        if (problemMappedTo.includes('Use of bad expressions')) {
            solutionMappedTo.push(SolutionHowToRegardusageofgoodexpressions);
        }
        if (problemMappedTo.includes('Use of non-portable words')) {
            solutionMappedTo.push(SolutionHowToRegardusageofportablewords);
        }
        if (problemMappedTo.includes('Use of non-portable expressions')) {
            solutionMappedTo.push(SolutionHowToRegardusageofportableexpressions);
        }
        if (problemMappedTo.includes('Use of words with multiple meanings')) {
            solutionMappedTo.push(SolutionHowToRegardusageofwordswithasinglemeaning);
        }
        if (problemMappedTo.includes('Use of words with multiple meanings')) {
            solutionMappedTo.push(SolutionHowToRegardusageoftherightmeaningoftheword);
        }
        if (problemMappedTo.includes('Word matching error to form expression')) {
            solutionMappedTo.push(SolutionHowToRemovetheunmatchedwordandreplacewithamatchedword);
        }
        if (problemMappedTo.includes('Word does not match in sentence')) {
            solutionMappedTo.push(SolutionHowToReplacewithawordthatisrelatedormatchedinthesentence);
        }
        if (problemMappedTo.includes('Words do not identify actual entity')) {
            solutionMappedTo.push(SolutionHowToRegardusageofwordsthatidentifyactualentity);
        }
        if (problemMappedTo.includes('Provide misinformation')) {
            solutionMappedTo.push(SolutionHowToProvideInformation);
        }
    }

    return solutionMappedTo;
}

function getCorrectionForComunicationDomain(problemMappedTo, type) {
    let solutionMappedTo = [];
    if (problemMappedTo.includes('Perform inappropriate actions')) {
        solutionMappedTo.push(CorrectionPerformInappropriateAction);
    }
    if (problemMappedTo.includes('Communicate about performing inappropriate actions')) {
        solutionMappedTo.push(CorrectionCommunicateaboutPerformInappropriateAction);
    }
    if (problemMappedTo.includes('Misuse of instructions')) {
        solutionMappedTo.push(CorrectionMisuseofInstructions);
    }
    if (problemMappedTo.includes('Communicate about misusing of instructions')) {
        solutionMappedTo.push(CorrectionCommunicateaboutMisuseofInstructions);
    }
    if (problemMappedTo.includes('Use inappropriate instructions')) {
        solutionMappedTo.push(CorrectionUseInappropriateInstructions);
    }
    if (problemMappedTo.includes('Communicate about using inappropriate instructions')) {
        solutionMappedTo.push(CorrectionCommunicateaboutUsingInappropriateInstructions);
    }
    if (problemMappedTo.includes('Disregard instructions')) {
        solutionMappedTo.push(CorrectiondisregardInstructions);
    }
    if (problemMappedTo.includes('Communicate about disregarding instructions')) {
        solutionMappedTo.push(CorrectionCommunicateaboutDisregardingInstructions);
    }
    if (problemMappedTo.includes('Misuse of objects')) {
        solutionMappedTo.push(CorrectionMisuseofObjects);
    }
    if (problemMappedTo.includes('Communicate about misuse of objects')) {
        solutionMappedTo.push(CorrectionCommunicateaboutMisuseofObjects);
    }
    if (problemMappedTo.includes('Use inappropriate objects')) {
        solutionMappedTo.push(CorrectionUseInappropriateObjects);
    }
    if (problemMappedTo.includes('Communicate about using inappropriate objects')) {
        solutionMappedTo.push(CorrectionCommunicateaboutUsingInappropriateObjects);
    }
    if (problemMappedTo.includes('Misinformation')) {
        solutionMappedTo.push(CorrectionMisinformation);
    }
    if (problemMappedTo.includes('Communicate about misinformation')) {
        solutionMappedTo.push(CorrectionCommunicateaboutMisinformation);
    }
    if (problemMappedTo.includes('Misinterpretation')) {
        solutionMappedTo.push(CorrectionMisinterpretation);
    }
    if (problemMappedTo.includes('Communicate about Misinterpretation')) {
        solutionMappedTo.push(CorrectionCommunicateaboutMisinterpretation);
    }
    if (problemMappedTo.includes('Follow others')) {
        solutionMappedTo.push(CorrectionFollowOthers);
    }
    if (problemMappedTo.includes('Communicate about following others')) {
        solutionMappedTo.push(CorrectionCommunicateaboutFollowingOthers);
    }
    if (problemMappedTo.includes('Miscommunication')) {
        solutionMappedTo.push(CorrectionMiscommunication);
    }
    if (problemMappedTo.includes('Communicate about miscommunication')) {
        solutionMappedTo.push(CorrectionCommunicateaboutMiscommunication);
    }
    if (problemMappedTo.includes('Exercise of force')) {
        solutionMappedTo.push(CorrectionExerciseofForce);
    }
    if (problemMappedTo.includes('Communicate about exercising of force')) {
        solutionMappedTo.push(CorrectionCommunicateaboutExercisingofForce);
    }
    if (problemMappedTo.includes('Disregard principles')) {
        solutionMappedTo.push(CorrectionDisregardPrinciples);
    }
    if (problemMappedTo.includes('Communicate about disregarding principles')) {
        solutionMappedTo.push(CorrectionCommunicateaboutDisregardingPrinciples);
    }
    if (problemMappedTo.includes('Use of bad words')) {
        solutionMappedTo.push(CorrectionUseofBadWords);
    }
    if (problemMappedTo.includes('Use of bad expressions')) {
        solutionMappedTo.push(CorrectionUseofBadExpressions);
    }
    if (problemMappedTo.includes('Use of non-portable words')) {
        solutionMappedTo.push(CorrectionUseofNonportableWords);
    }
    if (problemMappedTo.includes('Use of non-portable expressions')) {
        solutionMappedTo.push(CorrectionUseofNonportableExpressions);
    }
    if (problemMappedTo.includes('Use of words with multiple meanings')) {
        solutionMappedTo.push(CorrectionUseofWordswithMultipleMeanings);
    }
    if (problemMappedTo.includes('Use of words with multiple meanings')) {
        solutionMappedTo.push(CorrectionUseofWordswithMultipleMeanings);
    }
    if (problemMappedTo.includes('Word matching error to form expression')) {
        solutionMappedTo.push(CorrectionWordMatchingErrortoFormExpression);
    }
    if (problemMappedTo.includes('Word does not match in sentence')) {
        solutionMappedTo.push(CorrectionWorddoesnotMatchinSentence);
    }
    if (problemMappedTo.includes('Words do not identify actual entity')) {
        solutionMappedTo.push(CorrectionWordsdonotidentifyactualentity);
    }
    if (problemMappedTo.includes('Provide misinformation')) {
        solutionMappedTo.push(CorrectionProvideMisinformation);
    }
    return solutionMappedTo;
}


function getSolutionHowToPoints(problemMappedTo, type) {
    let solutionMappedTo = [];
    if (type === domainType.theory) {
        if (problemMappedTo.includes('Disregarding Application of Theory')) {
            solutionMappedTo.push('Understand how to apply theory');
            solutionMappedTo.push('Learn how to apply theory');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Misinterpretation of Theory')) {
            solutionMappedTo.push('Understand theory of communication');
            solutionMappedTo.push('Learn how to communicate properly');
            solutionMappedTo.push('Learn how to interpret theory');
            solutionMappedTo.push('Learn how to apply theory');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Error in Presentation of Theory or Improper Presentation of Theory')) {
            solutionMappedTo.push('Learn how to present theory');
            solutionMappedTo.push('Learn how to understand theory');
            solutionMappedTo.push('Learn how to communicate');
            solutionMappedTo.push('Learn how to apply theory');
            solutionMappedTo.push('Learn theory of communication');
            solutionMappedTo.push('Learn theory of education');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Disregard Importance of Theory')) {
            solutionMappedTo.push('Learn the theory');
            solutionMappedTo.push('Apply the theory');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Disregard Relationship of Theory and Theory of Communication')) {
            solutionMappedTo.push('Learn theory of communication');
            solutionMappedTo.push('Apply theory of communication');
            solutionMappedTo.push('Learn the relationship of theory and theory of communication');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Disregard Portability of Theory')) {
            solutionMappedTo.push('Implement portability of theory');
            solutionMappedTo.push('Learn theory and theory of communication');
            solutionMappedTo.push('Apply the theory and theory of communication everywhere');
            solutionMappedTo.push('Promote portability of theory');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Disregard Theory and System Relationship')) {
            solutionMappedTo.push('Learn theory and system relationship');
            solutionMappedTo.push('Apply theory and system relationship');
            solutionMappedTo.push('Learn and apply theory of communication');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Disregard Independency of Theory')) {
            solutionMappedTo.push('See presentation of theory above');
            solutionMappedTo.push('See interpretation of theory above');
            solutionMappedTo.push('Learn the theory and theory of communication');
            solutionMappedTo.push('Apply theory and system relationship');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Expandability of Philosophy')) {
            solutionMappedTo.push('Drop the philosophy');
            solutionMappedTo.push('Learn and apply the theory');
            solutionMappedTo.push('Then the theory can be expandable through application');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Misapplication of Instruments')) {
            solutionMappedTo.push('Apply instruments properly');
            solutionMappedTo.push('Learn how to apply instruments');
            solutionMappedTo.push('Learn how to communicate');
            solutionMappedTo.push('Learn system and system relationship');
            solutionMappedTo.push('Learn theory and system');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Misuse of Good Instruments')) {
            solutionMappedTo.push('Learn how to use instrument properly');
            solutionMappedTo.push('Learn how to communicate');
            solutionMappedTo.push('Learn instrument and system relationship');
            solutionMappedTo.push('Learn system and system relationship');
            solutionMappedTo.push('Learn theory and system relationship');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Disregard Instrument and System Relationship')) {
            solutionMappedTo.push('Learn system and system relationship');
            solutionMappedTo.push('Learn instrument and system relationship');
            solutionMappedTo.push('Apply instrument and system relationship');
            solutionMappedTo.push('Apply system and system relationship');
            solutionMappedTo.push('Apply theory and system');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Disregard System and System Relationship')) {
            solutionMappedTo.push('Apply system and system relationship');
            solutionMappedTo.push('Apply theory and system relationship');
            solutionMappedTo.push('Apply instrument and system relationship');
            solutionMappedTo.push('Learn about importance of theory');
            solutionMappedTo.push('Learn and apply portability of theory');
            solutionMappedTo.push('See expandability of theory');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Disregard Function and System Relationship')) {
            solutionMappedTo.push('Learn function and system relationship');
            solutionMappedTo.push('Apply function and system relationship');
            solutionMappedTo.push('Learn system and system relationship');
            solutionMappedTo.push('Apply system and system relationship');
            solutionMappedTo.push('Learn instrument and system relationship');
            solutionMappedTo.push('Apply instrument and system relationship');
            solutionMappedTo.push('See portability of theory');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Misapplication of Method')) {
            solutionMappedTo.push('Learn instrument and system relationship');
            solutionMappedTo.push('Apply instrument and system relationship');
            solutionMappedTo.push('Learn system and system relationship');
            solutionMappedTo.push('Apply system and system relationship');
            solutionMappedTo.push('See portability of theory');
            solutionMappedTo.push('See expandability of theory');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Application of Bad Methods')) {
            solutionMappedTo.push('Learn method and system relationship');
            solutionMappedTo.push('Learn instruments and system relationship');
            solutionMappedTo.push('Learn system and system relationship');
            solutionMappedTo.push('See portability of theory');
            solutionMappedTo.push('Apply system and system relationship');
            solutionMappedTo.push('Apply instrument and system relationship');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Disregard Method and System Relationship')) {
            solutionMappedTo.push('Learn method and system relationship');
            solutionMappedTo.push('Apply method and system relationship');
            solutionMappedTo.push('Learn instrument and system relationship');
            solutionMappedTo.push('Apply instrument and system relationship');
            solutionMappedTo.push('Learn system and system relationship');
            solutionMappedTo.push('Apply system and system relationship');
            solutionMappedTo.push('See portability of theory');
            solutionMappedTo.push('See expandability of theory');
            solutionMappedTo.push(' ');
        }
    } else {
        if (problemMappedTo.includes('Perform inappropriate actions')) {
            solutionMappedTo.push('Perform appropriate actions');
            solutionMappedTo.push('Communicate about perform appropriate actions');
            solutionMappedTo.push('Provide feedback to perform appropriate actions');
            solutionMappedTo.push('Apply feedback to perform appropriate actions');
            solutionMappedTo.push('Request feedback to perform appropriate actions');
            solutionMappedTo.push('Receive feedback to perform appropriate actions');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Communicate about performing inappropriate actions')) {
            if (!problemMappedTo.includes('Perform inappropriate actions')) {
                solutionMappedTo.push('Perform appropriate actions');
                solutionMappedTo.push('Communicate about perform appropriate actions');
                solutionMappedTo.push('Provide feedback to perform appropriate actions');
                solutionMappedTo.push('Apply feedback to perform appropriate actions');
                solutionMappedTo.push('Request feedback to perform appropriate actions');
                solutionMappedTo.push('Receive feedback to perform appropriate actions');
                solutionMappedTo.push(' ');
            }
        }
        if (problemMappedTo.includes('Misuse of instructions')) {
            solutionMappedTo.push('Good usage of instructions');
            solutionMappedTo.push('Communicate about good usage of instructions');
            solutionMappedTo.push('Provided feedback about good usage of instructions');
            solutionMappedTo.push('Apply feedback about good usage of instructions');
            solutionMappedTo.push('Request feedback about good usage of instructions');
            solutionMappedTo.push('Receive feedback about good usage of instructions');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Communicate about misusing of instructions')) {
            if (!problemMappedTo.includes('Misuse of instructions')) {
                solutionMappedTo.push('Good usage of instructions');
                solutionMappedTo.push('Communicate about good usage of instructions');
                solutionMappedTo.push('Provided feedback about good usage of instructions');
                solutionMappedTo.push('Apply feedback about good usage of instructions');
                solutionMappedTo.push('Request feedback about good usage of instructions');
                solutionMappedTo.push('Receive feedback about good usage of instructions');
                solutionMappedTo.push(' ');
            }
        }
        if (problemMappedTo.includes('Use inappropriate instructions')) {
            solutionMappedTo.push('Use appropriate instructions');
            solutionMappedTo.push('Communicate about using appropriate instructions');
            solutionMappedTo.push('Provided feedback to use appropriate instructions');
            solutionMappedTo.push('Apply feedback to use appropriate instructions');
            solutionMappedTo.push('Request feedback to use appropriate instructions');
            solutionMappedTo.push('Receive feedback to use appropriate instructions');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Communicate about using inappropriate instructions')) {
            if (!problemMappedTo.includes('Use inappropriate instructions')) {
                solutionMappedTo.push('Use appropriate instructions');
                solutionMappedTo.push('Communicate about using appropriate instructions');
                solutionMappedTo.push('Provided feedback to use appropriate instructions');
                solutionMappedTo.push('Apply feedback to use appropriate instructions');
                solutionMappedTo.push('Request feedback to use appropriate instructions');
                solutionMappedTo.push('Receive feedback to use appropriate instructions');
                solutionMappedTo.push(' ');
            }
        }
        if (problemMappedTo.includes('Disregard instructions')) {
            solutionMappedTo.push('Follow instructions');
            solutionMappedTo.push('Communicate about following instructions');
            solutionMappedTo.push('Provided feedback about following instructions');
            solutionMappedTo.push('Apply feedback to follow instructions');
            solutionMappedTo.push('Request feedback to follow instructions');
            solutionMappedTo.push('Receive feedback to follow instructions');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Communicate about disregarding instructions')) {
            if (!problemMappedTo.includes('Disregard instructions')) {
                solutionMappedTo.push('Follow instructions');
                solutionMappedTo.push('Communicate about following instructions');
                solutionMappedTo.push('Provided feedback about following instructions');
                solutionMappedTo.push('Apply feedback to follow instructions');
                solutionMappedTo.push('Request feedback to follow instructions');
                solutionMappedTo.push('Receive feedback to follow instructions');
                solutionMappedTo.push(' ');
            }
        }
        if (problemMappedTo.includes('Misuse of objects')) {
            solutionMappedTo.push('Good use of objects');
            solutionMappedTo.push('Communicate about good use of objects');
            solutionMappedTo.push('Provided feedback about good use of objects');
            solutionMappedTo.push('Apply feedback about good use of objects');
            solutionMappedTo.push('Request feedback about good use of objects');
            solutionMappedTo.push('Receive feedback about good use of objects');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Communicate about misuse of objects')) {
            if (!problemMappedTo.includes('Misuse of objects')) {
                solutionMappedTo.push('Good use of objects');
                solutionMappedTo.push('Communicate about good use of objects');
                solutionMappedTo.push('Provided feedback about good use of objects');
                solutionMappedTo.push('Apply feedback about good use of objects');
                solutionMappedTo.push('Request feedback about good use of objects');
                solutionMappedTo.push('Receive feedback about good use of objects');
                solutionMappedTo.push(' ');
            }
        }
        if (problemMappedTo.includes('Use inappropriate objects')) {
            solutionMappedTo.push('Use appropriate objects');
            solutionMappedTo.push('Communicate about using appropriate objects');
            solutionMappedTo.push('Provided feedback to use appropriate objects');
            solutionMappedTo.push('Apply feedback to use appropriate objects');
            solutionMappedTo.push('Request feedback to use appropriate objects');
            solutionMappedTo.push('Receive feedback to use appropriate objects');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Communicate about using inappropriate objects')) {
            if (!problemMappedTo.includes('Use inappropriate objects')) {
                solutionMappedTo.push('Use appropriate objects');
                solutionMappedTo.push('Communicate about using appropriate objects');
                solutionMappedTo.push('Provided feedback to use appropriate objects');
                solutionMappedTo.push('Apply feedback to use appropriate objects');
                solutionMappedTo.push('Request feedback to use appropriate objects');
                solutionMappedTo.push('Receive feedback to use appropriate objects');
                solutionMappedTo.push(' ');
            }
        }
        if (problemMappedTo.includes('Misinformation')) {
            solutionMappedTo.push('Proper information');
            solutionMappedTo.push('Communicate about proper information');
            solutionMappedTo.push('Provided feedback about proper information');
            solutionMappedTo.push('Apply feedback about proper information');
            solutionMappedTo.push('Request feedback about proper information');
            solutionMappedTo.push('Receive feedback about proper information');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Communicate about misinformation')) {
            if (!problemMappedTo.includes('Misinformation')) {
                solutionMappedTo.push('Proper information');
                solutionMappedTo.push('Communicate about proper information');
                solutionMappedTo.push('Provided feedback about proper information');
                solutionMappedTo.push('Apply feedback about proper information');
                solutionMappedTo.push('Request feedback about proper information');
                solutionMappedTo.push('Receive feedback about proper information');
                solutionMappedTo.push(' ');
            }
        }
        if (problemMappedTo.includes('Misinterpretation')) {
            solutionMappedTo.push('Good interpretation');
            solutionMappedTo.push('Communicate about good interpretation');
            solutionMappedTo.push('Provided feedback about good interpretation');
            solutionMappedTo.push('Apply feedback about good interpretation');
            solutionMappedTo.push('Request feedback about good interpretation');
            solutionMappedTo.push('Receive feedback about good interpretation');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Communicate about Misinterpretation')) {
            if (!problemMappedTo.includes('Misinterpretation')) {
                solutionMappedTo.push('Good interpretation');
                solutionMappedTo.push('Communicate about good interpretation');
                solutionMappedTo.push('Provided feedback about good interpretation');
                solutionMappedTo.push('Apply feedback about good interpretation');
                solutionMappedTo.push('Request feedback about good interpretation');
                solutionMappedTo.push('Receive feedback about good interpretation');
                solutionMappedTo.push(' ');
            }
        }
        if (problemMappedTo.includes('Follow others')) {
            solutionMappedTo.push('Follow principles');
            solutionMappedTo.push('Communicate about following principles');
            solutionMappedTo.push('Provided feedback to follow principles');
            solutionMappedTo.push('Apply feedback to follow principles');
            solutionMappedTo.push('Request feedback to follow principles');
            solutionMappedTo.push('Receive feedback to follow principles');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Communicate about following others')) {
            if (!problemMappedTo.includes('Follow others')) {
                solutionMappedTo.push('Follow principles');
                solutionMappedTo.push('Communicate about following principles');
                solutionMappedTo.push('Provided feedback to follow principles');
                solutionMappedTo.push('Apply feedback to follow principles');
                solutionMappedTo.push('Request feedback to follow principles');
                solutionMappedTo.push('Receive feedback to follow principles');
                solutionMappedTo.push(' ');
            }
        }
        if (problemMappedTo.includes('Miscommunication')) {
            solutionMappedTo.push('Proper Communication');
            solutionMappedTo.push('Communicate about proper Communication');
            solutionMappedTo.push('Provided feedback about proper communication');
            solutionMappedTo.push('Apply feedback about proper communication');
            solutionMappedTo.push('Request feedback about proper communication');
            solutionMappedTo.push('Receive feedback about proper communication');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Communicate about miscommunication')) {
            if (!problemMappedTo.includes('Miscommunication')) {
                solutionMappedTo.push('Proper Communication');
                solutionMappedTo.push('Communicate about proper Communication');
                solutionMappedTo.push('Provided feedback about proper communication');
                solutionMappedTo.push('Apply feedback about proper communication');
                solutionMappedTo.push('Request feedback about proper communication');
                solutionMappedTo.push('Receive feedback about proper communication');
                solutionMappedTo.push(' ');
            }
        }
        if (problemMappedTo.includes('Exercise of force')) {
            solutionMappedTo.push('Exercise kindness');
            solutionMappedTo.push('Communicate about exercising of kindness');
            solutionMappedTo.push('Provided feedback about exercise kindness');
            solutionMappedTo.push('Apply feedback about exercise kindness');
            solutionMappedTo.push('Request feedback about exercise kindness');
            solutionMappedTo.push('Receive feedback about exercise kindness');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Communicate about exercising of force')) {
            if (!problemMappedTo.includes('Exercise of force')) {
                solutionMappedTo.push('Exercise kindness');
                solutionMappedTo.push('Communicate about exercising of kindness');
                solutionMappedTo.push('Provided feedback about exercise kindness');
                solutionMappedTo.push('Apply feedback about exercise kindness');
                solutionMappedTo.push('Request feedback about exercise kindness');
                solutionMappedTo.push('Receive feedback about exercise kindness');
                solutionMappedTo.push(' ');
            }
        }
        if (problemMappedTo.includes('Disregard principles')) {
            solutionMappedTo.push('Apply principles');
            solutionMappedTo.push('Communicate about applying principles');
            solutionMappedTo.push('Provided feedback to apply principles');
            solutionMappedTo.push('Apply feedback to apply principles');
            solutionMappedTo.push('Request feedback to apply principles');
            solutionMappedTo.push('Receive feedback to apply principles');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Communicate about disregarding principles')) {
            if (!problemMappedTo.includes('Disregard principles')) {
                solutionMappedTo.push('Apply principles');
                solutionMappedTo.push('Communicate about applying principles');
                solutionMappedTo.push('Provided feedback to apply principles');
                solutionMappedTo.push('Apply feedback to apply principles');
                solutionMappedTo.push('Request feedback to apply principles');
                solutionMappedTo.push('Receive feedback to apply principles');
                solutionMappedTo.push(' ');
            }
        }
        if (problemMappedTo.includes('Use of bad words')) {
            solutionMappedTo.push('Regard usage of good words');
            solutionMappedTo.push('Provided feedback to use good words');
            solutionMappedTo.push('Apply feedback to use good words');
            solutionMappedTo.push('Request feedback to use good words');
            solutionMappedTo.push('Receive feedback to use good words');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Use of bad expressions')) {
            solutionMappedTo.push('Regard usage of good expressions');
            solutionMappedTo.push('Provided feedback to use good expressions');
            solutionMappedTo.push('Apply feedback to use good expressions');
            solutionMappedTo.push('Request feedback to use good expressions');
            solutionMappedTo.push('Receive feedback to use good expressions');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Use of non-portable words')) {
            solutionMappedTo.push('Regard usage of portable words');
            solutionMappedTo.push('Provided feedback to use portable words');
            solutionMappedTo.push('Apply feedback to portable words');
            solutionMappedTo.push('Request feedback to use portable words');
            solutionMappedTo.push('Receive feedback to portable words');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Use of non-portable expressions')) {
            solutionMappedTo.push('Regard usage of portable expressions');
            solutionMappedTo.push('Apply feedback to use portable expressions');
            solutionMappedTo.push('Request feedback to use portable expressions');
            solutionMappedTo.push('Receive feedback to use portable expressions');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Use of words with multiple meanings')) {
            solutionMappedTo.push('Regard usage of words with a single meaning');
            solutionMappedTo.push('Provided feedback to use words with a single meaning');
            solutionMappedTo.push('Apply feedback to use words with a single meaning');
            solutionMappedTo.push('Request feedback to use words with a single meaning');
            solutionMappedTo.push('Receive feedback to use words with a single meaning');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Use of words with multiple meanings')) {
            solutionMappedTo.push('Regard usage of the right meaning of the word, rather than using the word');
            solutionMappedTo.push('Provided feedback to use the right meaning of the word, rather than using the word');
            solutionMappedTo.push('Apply feedback to use the right meaning of the word, rather than using the word');
            solutionMappedTo.push('Request feedback to use the right meaning of the word, rather than using the word');
            solutionMappedTo.push('Receive feedback to use the right meaning of the word, rather than using the word');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Word matching error to form expression')) {
            solutionMappedTo.push('Remove the unmatched word and replace with a matched word');
            solutionMappedTo.push('Provided feedback to remove the unmatched word and replace with a matched word');
            solutionMappedTo.push('Apply feedback to remove the unmatched word and replace with a matched word');
            solutionMappedTo.push('Request feedback to remove the unmatched word and replace with a matched word');
            solutionMappedTo.push('Receive feedback to remove the unmatched word and replace with a matched word');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Word does not match in sentence')) {
            solutionMappedTo.push('Replace with a word that is related or matched in the sentence');
            solutionMappedTo.push('Provided feedback to replace with a word that is related or matched in the sentence');
            solutionMappedTo.push('Apply feedback to replace with a word that is related or matched in the sentence');
            solutionMappedTo.push('Request feedback to replace with a word that is related or matched in the sentence');
            solutionMappedTo.push('Receive feedback to replace with a word that is related or matched in the sentence');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Words do not identify actual entity')) {
            solutionMappedTo.push('Regard usage of words that identify actual entity');
            solutionMappedTo.push('Provided feedback to use words that identify actual entity');
            solutionMappedTo.push('Apply feedback use words that identify actual entity');
            solutionMappedTo.push('Request feedback use words that identify actual entity');
            solutionMappedTo.push('Receive feedback use words that identify actual entity');
            solutionMappedTo.push(' ');
        }
        if (problemMappedTo.includes('Provide misinformation')) {
            solutionMappedTo.push('Provide information');
            solutionMappedTo.push('Provided feedback to provide information');
            solutionMappedTo.push('Apply feedback to provide information');
            solutionMappedTo.push('Request feedback to provide information');
            solutionMappedTo.push('Receive feedback to provide information');
            solutionMappedTo.push(' ');
        }
    }
    return solutionMappedTo;
}



export { getSolutionMappedTo, getSolutionFromProblemMappedTo, getCorrectionForComunicationDomain, getSolutionHowToPoints };