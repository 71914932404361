import React, { useState } from 'react'
import LoginInputWidget from '../../../widgets/input/login_input';
import './login_box.css';
import PersonIcon from '@mui/icons-material/Person';
import HttpsIcon from '@mui/icons-material/Https';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Link, useNavigate } from 'react-router-dom';
import CircleProgress from '../../../widgets/circle_prgress/circle_progress';
import { loginUser } from '../../../api/auth';
import { showAlert } from '../../../functions/show_alert';
import { authenticationPage } from '../authentication';
export default function LoginBox({ SetPage }) {
    const navigate = useNavigate();
    const [sendingData, SetSendingData] = useState(false);
    const [email, SetEmail] = useState('');
    const [password, SetPassword] = useState('');
    const loginFunction = async () => {
        SetSendingData(true);
        const response = await loginUser(email, password);
        if (response.statusCode === 200) {
            navigate('/', { replace: true });
            showAlert(true, 'Welcome To Problem Mapping');
        } else {
            showAlert(false, response.response);
            SetSendingData(false);
        }
    }
    return (
        <div id='login-box'>
            <h1 className='login-box-title'>Sign into your account </h1>
            <form className='form-login'>
                <LoginInputWidget width={'297px'} height={'50px'} type={'text'} widget={<PersonIcon />} placeholder={'Enter a valid email address'} setValue={SetEmail} value={email} />
                <LoginInputWidget width={'297px'} height={'50px'} type={'password'} widget={<HttpsIcon />} placeholder={'Enter a valid password'} setValue={SetPassword} value={password} />
            </form>
            <div onClick={() => SetPage(authenticationPage.forgotpassword)} className='forget-password-link'>Forget password</div>
            {
                sendingData ? <CircleProgress width={'50px'} height={'50px'} /> : <div onClick={() => loginFunction()} className='login-button'>
                    <h1>LOGIN</h1>
                    <ArrowCircleRightIcon className='icon' />
                </div>
            }

            <div className="create-account-link">
                don't have account ? <Link onClick={() => SetPage(authenticationPage.signin)} className='link' >create account </Link>
            </div>
        </div>
    )
}
